import { GOODWAY_LIGHT, GOODWAY_YELLOW } from '../../constants/colors';
import { Box, Grid, IconButton, Modal } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import React from 'react';
import { StyledModalHeader } from './StyledModalHeader';

const StyledModal = ({ children, open, onClose, onClick = () => {}, header }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
  >
    <Grid
      container
      direction='column'
      wrap='nowrap'
      sx={{
        backgroundColor: GOODWAY_LIGHT,
        width: '50%',
        p: 4,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px',
        '&:focus': {
          outline: 'none',
          border: 'none',
        },
      }}
    >
      {onClose && onClick && (
        <Grid item xs={12} sx={{ width: '100%', paddingTop: '0 !important', paddingLeft: 0 }}>
          <Grid container sx={{ justifyContent: 'flex-end', paddingTop: '0 !important' }}>
            <IconButton
              sx={{ color: GOODWAY_YELLOW }}
              onClick={() => {
                onClick();
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {!!header && <StyledModalHeader header={header} />}
      <Grid
        item
        xs={12}
        sx={{
          maxHeight: '60vh',
          paddingTop: 4,
        }}
      >
        {children}
      </Grid>
    </Grid>
  </Modal>
);

export default StyledModal;
