// get file name without the bucket included
export const getFileName = str => {
  if (!str) {
    ('uploading...');
  } else {
    let match = str.match(
      /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}\/(.*)/
    );
    return match[1];
  }
};
