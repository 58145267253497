import { useState } from 'react';
import WarnUserContext from './WarnUserContext';

const IGNORE_UNSAVED_CHANGES = import.meta.env.VITE_IGNORE_UNSAVED_CHANGES == 'true';

const WarnUserProvider = ({ children }) => {
  const [warnUser, setWarnUser] = useState(false);

  const message = 'You have unsaved changes! Are you sure you want to leave?';

  return (
    <WarnUserContext.Provider
      value={{ warnUser: IGNORE_UNSAVED_CHANGES ? false : warnUser, setWarnUser, message }}
    >
      {children}
    </WarnUserContext.Provider>
  );
};

export default WarnUserProvider;
