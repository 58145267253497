import styled from 'styled-components';
import { Box } from '@mui/material';
import { getBase64 } from '../../helpers/utils';
import StyledDataGrid from '../atoms/StyledDataGrid';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextWithToolTipCell from '../atoms/FileNameCell';
import { FilePreview } from '../atoms/FilePreview';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPLOAD_ASSET } from '../../queries/assets';
import { useFilePicker } from 'use-file-picker';
import { getFileName } from '../../helpers/file';
import { UploadButton } from '../atoms/UploadButton';
import { ApplyButton } from '../atoms/ApplyButton';

const HelperText = styled.p`
  color: #bdbdbd;
  text-align: center;
`;
const InnerContainer = styled(Box)`
    width: 60vw;
    height: 60vh;
    border-radius: 10px; 
    margin-left: -3px;
    margin-top: -3px;
    border: ${props => (props.hasFiles ? 'none' : '3px dashed #e6e6e6')};
    display: flex;
    flex-direction: ${props => (props.hasFiles ? 'column' : 'row')}
    justify-content: center;
    align-items: center;
`;
const FileButtonContainer = styled(Box)`
  display: flex;
  flex-direction: ${props => (props.hasFiles ? 'row-reverse' : 'column')};
  gap: ${props => (props.hasFiles ? '10px' : '0px')};
  margin-bottom: 10px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
`;
const FileContainer = styled(Box)`
    width: 100%;
    height: calc(90% - 20px);
    max-height: 90%;
    display: flex;
    flex-direction: 
    margin-bottom: 20px;
`;
const Files = styled(Box)`
    padding: 20px;
    box-sizing: border-box; 
    width: 35%
    overflow-y: auto;
    overflow-x: hidden;
`;
const Selection = styled(Box)`
    width: 100%;
    height: 100%
    max-height: 100%;
    border: ${props => (props.selection ? 'none' : '3px dashed #e6e6e6')} ;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const SelectedPreview = styled(Box)`
  height: 100%;
  max-height: 100%;
  width: 65%;
  padding-top: 35px;
  padding-right: 10px
  margin-left: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
// handle the drop of any file with a type starting with image/
// adds to files state {base64,fileName}
const handleDrop = async function (e, addFiles) {
  e.preventDefault();
  e.stopPropagation();

  if (e.dataTransfer?.files?.[0]?.type?.startsWith('image/') ?? false) {
    let converted = await Promise.all(
      Array.from(e.dataTransfer.files).map(async file => ({
        base64: await getBase64(file),
        name: file.name,
      }))
    );
    addFiles(converted.map(({ base64, name }) => ({ content: base64, name })));
  }
};

export const FileUploadContainer = ({
  assets = [],
  onApply = () => {},
  onSelect = () => {},
  bucket,
  onUpload,
  initialSelection,
}) => {
  const [files, setFiles] = useState(assets);
  const [selection, setSelection] = useState(initialSelection);
  const [singleUpload, isSingleUpload] = useState(false);

  const [uploadAsset] = useMutation(UPLOAD_ASSET);
  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: 'image/*',
    readAs: 'DataURL',
    multiple: true,
  });

  useEffect(() => {
    let newFiles = assets.filter(newFile => {
      if (files.find(file => file.url == newFile.url)) {
        return false;
      } else if (!!newFile.content && files.find(file => file.content == newFile.content)) {
        return false;
      } else {
        return true;
      }
    });
    setFiles([...files, ...newFiles]);
  }, [assets]);

  const getFileBySelection = () => files.find(file => file.key === selection) || {};

  //any time fileContents changes call uploadFiles on the new files.
  useEffect(() => {
    if (filesContent.length) {
      uploadFiles(filesContent);
    }
  }, [JSON.stringify(filesContent)]);

  useEffect(() => {
    //if a single upload just happened and file length is 1 select this file automatically.
    if (!!singleUpload && !selection) {
      setOrUnsetSelection(files[files.length - 1].key);
      isSingleUpload(false);
    }
  }, [files]);

  // set selection unless it is for the same id already set, then null.
  const setOrUnsetSelection = id => setSelection(current => (current === id ? null : id));

  const hasFiles = !!files.length;

  const rows = useMemo(
    () =>
      files.map(file => ({
        ...file,
        id: file.key || file.content,
        fileName: getFileName(file.key),
      })),
    [files]
  );

  // function to upload files to s3.
  // looping instead of doing promise all so if 1 image is to big it does not short circuit
  const uploadFiles = async data => {
    await data.forEach(async asset => {
      let result = await uploadAsset({
        variables: {
          base64: asset.content,
          bucket: bucket,
          fileName: asset.name,
        },
      });
      if (data.length === 1) {
        isSingleUpload(true);
      }
      onUpload(result.data.uploadAsset);
    });
  };

  return (
    <InnerContainer
      onDrop={e => handleDrop(e, uploadFiles)}
      onDragEnter={e => e.preventDefault()}
      onDragLeave={e => e.preventDefault()}
      onDragOver={e => e.preventDefault()}
      hasFiles={hasFiles}
    >
      {hasFiles && (
        <FileContainer>
          <Files>
            {
              <StyledDataGrid
                selection
                rows={rows}
                components={{
                  ColumnMenuIcon: FilterListIcon,
                }}
                selectionModel={selection}
                onRowClick={(data, e) => e.stopPropagation()}
                hideFooter={true}
                columns={[
                  {
                    field: 'fileName',
                    hideable: false,
                    headerName: `File Name`,
                    flex: 2,
                    renderCell: ({ row }) => (
                      <TextWithToolTipCell
                        onClick={() => {
                          setSelection(row.key);
                          onSelect(row.key);
                        }}
                        value={row.fileName}
                      />
                    ),
                  },
                ]}
              />
            }
          </Files>
          <SelectedPreview>
            <Selection selection={!!selection}>
              {!!selection ? (
                <FilePreview
                  preview
                  selected={false}
                  url={getFileBySelection().url}
                  data={getFileBySelection().content}
                />
              ) : (
                <HelperText>Select or upload an image up to 10MB</HelperText>
              )}
            </Selection>
          </SelectedPreview>
        </FileContainer>
      )}
      <FileButtonContainer hasFiles={hasFiles}>
        {hasFiles ? (
          <ApplyButton onApply={() => onApply({ ...getFileBySelection() })} />
        ) : (
          <UploadButton onClick={async () => await openFileSelector()} />
        )}
        <HelperText>
          {hasFiles ? (
            <UploadButton onClick={async () => await openFileSelector()} />
          ) : (
            '...or drag and drop files'
          )}
        </HelperText>
      </FileButtonContainer>
    </InnerContainer>
  );
};
