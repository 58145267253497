import styled from 'styled-components';
import { Button } from '@mui/material';

const StyledButton = styled(Button)`
  && {
    ${props => (props.keepCase ? 'text-transform: none !important;' : '')}
    color: ${props => props.highlightcolor || 'white'};
    margin-left: ${props => (props.marginleft ? props.marginleft : 'initial')};
    border-color: ${props => props.highlightcolor || 'white'};
    width: fit-content;
    align-self: ${props => (props.flexSelf ? props.flexSelf : 'flex-end')};
    font-weight: ${props => (props.bold ? 400 : 200)};
    ${props => !!props.$backgroundColor && `background-color: ${props.$backgroundColor}`}
  }
  &&:hover {
    color: ${props => {
      if (props.hoverColor) {
        return props.hoverColor;
      } else if (props.highlightcolor) {
        return props.highlightcolor;
      } else {
        return 'white';
      }
    }};
    border-color: ${props => props.highlightcolor || 'white'};
    width: fit-content;
    font-weight: ${props => (props.bold ? 400 : 200)};
    align-self: ${props => (props.flexSelf ? props.flexSelf : 'flex-end')};
    font-weight: ${props => (props.bold ? 400 : 200)};${props =>
  props.bold ? 400 : 200};${props => (props.bold ? 400 : 200)};${props =>
  props.bold ? 400 : 200};00
    ${props => !!props.$backgroundColor && `background-color: ${props.$backgroundColor}`}
    filter: brightness(120%);
  }
`;

export default StyledButton;
