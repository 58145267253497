import { MenuItem } from '@mui/material';
import React from 'react';
import { GOODWAY_DARK } from '../../constants/colors';
import OutlinedMultiSelect from '../atoms/OutlinedMultiSelect';

const MultiSelect = ({ value, defaultValue, onChange, menuItemConfig }) => {
  return (
    <OutlinedMultiSelect
      notched
      required
      value={[value] || [defaultValue]}
      variant='outlined'
      onChange={onChange}
      style={{
        width: '100%',
        marginBottom: '1rem',
        color: 'white',
      }}
      InputLabelProps={{
        shrink: true,
        style: { backgroundColor: GOODWAY_DARK, paddingRight: '12px' },
      }}
      sx={{
        width: '100%',
      }}
    >
      {menuItemConfig.map(({ id, name, key }) => (
        <MenuItem key={key} value={id}>
          {name || id}
        </MenuItem>
      ))}
    </OutlinedMultiSelect>
  );
};

export default MultiSelect;
