import { Link } from 'react-router-dom';

import StyledButton from '../atoms/StyledButton';
import { Box } from '@mui/material';
import PageLink from '../PageLink';

const LinkedButton = ({ children, ...props }) => {
  return (
    <Box>
      <PageLink to={props.link} style={{ textDecoration: 'none' }}>
        <StyledButton type='submit' variant='outlined' highlightcolor='#f2f2f2' bold='true'>
          {children}
        </StyledButton>
      </PageLink>
    </Box>
  );
};

export default LinkedButton;
