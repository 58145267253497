export default key => {
  // TODO: is this needed.

  // Production
  if (typeof window !== 'undefined' && window.goodway) {
    return window.goodway[key];
  }

  // Dev
  const value = import.meta.env[key];
  return value;
};
