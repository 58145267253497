import BulkEditTextField from './BulkEditTextField';
import { updateTagValue } from '../../helpers/utils';

const BulkEditTagField = ({ column, ...props }) => {
  const updateRow = (row, value) =>
    updateTagValue(value, row, column.tagId, column.currentDimension);

  return <BulkEditTextField {...props} updateRow={updateRow} column={column} />;
};

export default BulkEditTagField;
