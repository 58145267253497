import styled from 'styled-components';
import { Select } from '@mui/material';

const OutlinedMultiSelect = styled(Select)({
  '& path': {
    color: '#fcd119',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
    borderRadius: '15px',
  },
  '&&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fcd119',
    color: '#fcd119',
  },
  '&&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fcd119',
    color: '#fcd119',
  },
  '& .MuiAutocomplete-endAdornment': {
    right: '0px !important',
  },
  '.MuiOutlinedInput-input': {
    padding: '1em',
  },
  '& legend': {
    padding: '0 1em',
  },
});

export default OutlinedMultiSelect;
