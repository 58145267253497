import { TextField } from '@mui/material';
import React from 'react';
import { GOODWAY_YELLOW } from '../../constants/colors';

export const StyledAutocompleteInput = ({ label, ...params }) => (
  <TextField
    {...params}
    InputLabelProps={{
      shrink: true,
      style: {
        fontSize: '20px',
        color: '#fcd119',
        fontFamily: `'Roboto', 'Helvetica', 'Arial', sans-serif`,
        fontWeight: 200,
        letterSpacing: '0.05em',
        '&.Mui-focused': {
          color: GOODWAY_YELLOW,
        },
      },
    }}
    label={label}
  />
);
