import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { subscribe, unsubscribe } from '../../event';

const Toast = ({ open, onClose, children, isSubscribed, severity }) => {
  const [defaultIsOpen, setDefaultIsOpen] = useState(open || false);

  const defaultOnClose = () => setDefaultIsOpen(false);
  const handleOnClose = onClose || defaultOnClose;

  useEffect(() => {
    if (isSubscribed) {
      subscribe('showGraphQLError', () => setDefaultIsOpen(true));
    }
    // Return statement to clean up event listener
    return () => isSubscribed && unsubscribe('showGraphQLError', () => setDefaultIsOpen(false));
  }, [isSubscribed]);

  return (
    <Snackbar open={defaultIsOpen} onClose={handleOnClose}>
      <Alert onClose={handleOnClose} severity={severity} sx={{ width: '100%' }}>
        {children}
      </Alert>
    </Snackbar>
  );
};

Toast.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
  children: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool,
};

Toast.defaultProps = {
  open: false,
  onClose: undefined,
  isSubscribed: false,
};

export default Toast;
