import { useEffect, useState } from 'react';
import { get, set, isEqual } from 'lodash';

const useCachedState = (page, path, defaultValue) => {
  const getCachedValue = () => get(JSON.parse(localStorage.getItem(page)), path);

  const [value, setValue] = useState(getCachedValue()) || defaultValue;

  useEffect(() => {
    if (page && path) {
      const newValue = JSON.parse(localStorage.getItem(page)) || {};
      set(newValue, path, value);

      localStorage.setItem(page, JSON.stringify(newValue));
    }
  }, [value]);

  useEffect(() => {
    const newVal = getCachedValue() || value || defaultValue;

    if (!isEqual(value, newVal)) {
      setValue(newVal);
    }
  }, [page, path]);

  return [value, setValue];
};

export default useCachedState;
