import { Link } from 'react-router-dom';
import { useContext } from 'react';
import WarnUserContext from '../helpers/WarnUserContext';

const PageLink = ({ children, onClick = () => {}, ...props }) => {
  const { warnUser, message, setWarnUser } = useContext(WarnUserContext);

  const handleNavigate = e => {
    if (warnUser && !confirm(message)) {
      e.preventDefault();
    } else {
      setWarnUser(false);
    }

    onClick(e);
  };

  return (
    <Link {...props} onClick={handleNavigate}>
      {children}
    </Link>
  );
};

export default PageLink;
