import md5 from 'md5';
import { uniq } from 'lodash/array';
import { isEmpty } from 'lodash';

export const hashIds = data => {
  if (!data?.length) {
    return '';
  }

  return md5(JSON.stringify(data.map(({ id }) => id).sort()));
};

export const getCreativeUrl = tags => {
  return tags?.creative
    ? tags.creative.type == 'image'
      ? `https://goodlook-public-assets.s3.amazonaws.com/${tags.creative?.file?.key}`
      : tags.creative?.config?.tag_value
    : '';
};

export const areAllItemsEqual = array => {
  if (!array?.length) {
    return false;
  }

  const uniqueValues = uniq(array);

  return uniqueValues.length === 1;
};

export const updateTagValue = (value, row, tagId, grainType) => {
  const grain = `${grainType}_id`;

  return {
    type: 'tag',
    deleteRule: !value,
    config: {
      tag_type: 'alias',
      field: grain,
      key: row.grainID,
      bucket: tagId,
      grain,
      grain_id: row.grainID,
      tag_value: value,
      reference: false,
    },
  };
};

// function takes in a file and return the base64 of the file
export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export const filterNameOrAlias = (name, alias, searchTerm) => {
  const parsedName = name?.toLowerCase();
  const parsedAlias = alias?.toLowerCase();
  const parsedSearchTerm = searchTerm?.toLowerCase();

  return parsedName?.includes(parsedSearchTerm) || parsedAlias?.includes(parsedSearchTerm);
};
