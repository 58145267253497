import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from 'react';
import { styled, Tooltip, Typography, keyframes } from '@mui/material';
import ConfirmationButton from '../atoms/ConfirmationButton';
import LogoIcon from '../atoms/LogoIcon';
import WarningIcon from '@mui/icons-material/Warning';
import StyledChip from '../atoms/StyledChip';

const StyledSigmaDestinationChip = styled(StyledChip)(({ canUpdate }) => {
  return {
    paddingLeft: 10,
    marginTop: 8,
    backgroundColor: canUpdate ? '#fcd119 !important' : '#fff',
  };
});

const fadeInOutA = keyframes`
  0%, 40%, 100% { opacity: 1; }
  45%, 95% { opacity: 0; }
`;

const fadeInOutB = keyframes`
  0%, 45% { opacity: 0; }
  50%, 90% { opacity: 1; }
  95%, 100% { opacity: 0; }
`;

const AnimatedIconWrapper = styled('div')`
  position: relative;
  width: 24px;
  height: 24px;
`;

const FadingIconA = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${fadeInOutA} 6s infinite;
`;

const FadingIconB = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${fadeInOutB} 6s infinite;
`;

const SigmaDestinationChip = ({
  slug,
  destinationConfig,
  deleteDestination,
  isSaved,
  canUpdate,
  handleSave,
  isLoading,
}) => {
  const [url, setUrl] = useState(destinationConfig.baseUrl({ slug, destinationConfig }));

  useEffect(() => {
    setUrl(destinationConfig.baseUrl({ slug, destinationConfig }));
  }, [slug, destinationConfig]);

  return (
    <Tooltip
      title={
        canUpdate ? (
          <>
            <Typography variant='body1' mt='12px' mb='8px'>
              <b>UPDATE: An updated version of the Transactional Agency Template is available!</b>
            </Typography>
            <Typography variant='subtitle2' mt='12px' mb='8px'>
              You can{' '}
              <a
                href='https://goodwaygroup.atlassian.net/wiki/spaces/TPM/pages/866123874/RELEASE+NOTES'
                target='_blank'
                style={{ color: '#90CAF9' }}
              >
                view release notes here
              </a>{' '}
              to see what new features have been added recently.
            </Typography>
            <Typography variant='body2' mt='12px' mb='8px'>
              NOTE: Once you update to the new template, a new version will be created in the
              staging folder. Any customizations that you made to your dashboard will need to be
              applied to the updated version.
            </Typography>
            <ConfirmationButton
              fullWidth={true}
              buttonText={'Update Template'}
              confirmText={'Confirm Update?'}
              onClick={() => handleSave({ updateDestinationTemplates: [destinationConfig.id] })}
              isDisabled={isLoading}
            />
          </>
        ) : (
          url
        )
      }
    >
      <StyledSigmaDestinationChip
        label={destinationConfig.name}
        value={destinationConfig}
        clickable={isSaved}
        onClick={() => isSaved && url && window.open(url, '_blank')}
        canUpdate={canUpdate}
        icon={
          canUpdate ? (
            <AnimatedIconWrapper>
              <FadingIconA>
                <LogoIcon logo={destinationConfig.logo} />
              </FadingIconA>
              <FadingIconB>
                <WarningIcon />
              </FadingIconB>
            </AnimatedIconWrapper>
          ) : (
            <LogoIcon logo={destinationConfig.logo} />
          )
        }
        onDelete={e => {
          e.stopPropagation();
          deleteDestination(destinationConfig);
        }}
        deleteIcon={<CancelIcon />}
      />
    </Tooltip>
  );
};

export default SigmaDestinationChip;
