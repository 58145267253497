import { Autocomplete } from '@mui/material';
import { useState } from 'react';
import { GOODWAY_YELLOW, GOODWAY_SAND_400, GOODWAY_LIGHT } from '../../constants/colors';
import StyledTextField from './StyledTextField';

const CHANNELS = [
  'Advanced TV',
  'Audio',
  'Digital Out-of-Home',
  'Display',
  'Email',
  'Mobile',
  'Native',
  'Offline',
  'SEM',
  'Site Direct',
  'Social',
  'Youtube',
  'Video',
];

const ChannelSelect = ({
  selectValue,
  placeholder,
  onChange = () => {},
  getInputProps = () => {},
  label,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={selectValue}
      options={['', ...CHANNELS]}
      renderInput={params => (
        <StyledTextField
          {...params}
          label={label}
          variant={'outlined'}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            ...getInputProps(selectValue),
          }}
          placeholder={placeholder}
          sx={{
            width: '100%',
            marginTop: 1,
            marginBottom: 1,
            input: {
              height: 12,
              color: 'white',
              marginBottom: '-4px',
            },
            fieldset: {
              borderColor: GOODWAY_SAND_400,
              borderRadius: '10px',
            },
            '& .MuiOutlinedInput-root:hover': {
              fieldset: {
                borderColor: GOODWAY_YELLOW,
              },
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused': {
                fieldset: {
                  borderColor: GOODWAY_YELLOW,
                },
              },
            },
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              maxWidth: 'calc(100% - 40px)',
            },
            label: {
              color: GOODWAY_YELLOW,
              fontSize: '20px',
              backgroundColor: GOODWAY_LIGHT,
              paddingRight: '6px',
            },
          }}
        />
      )}
      onChange={onChange}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          paddingRight: '7px !important',
        },
        '& path': {
          color: GOODWAY_YELLOW,
        },
      }}
    />
  );
};

export default ChannelSelect;
