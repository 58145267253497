import styled from 'styled-components';
import Header from '../atoms/Header';
import PageTitle from '../atoms/PageTitle';
import Feedback from '../atoms/Feedback';
import Footer from '../atoms/Footer';
import WarnUserProvider from '../../helpers/WarnUserProvider';

const DatasetPageContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#1a1d30')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Content = styled.div`
  height: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const PageTemplate = ({ children, ...props }) => {
  return (
    <DatasetPageContainer backgroundColor={props.backgroundColor}>
      {props.alert}
      <Header color={props.logoColor} />
      <PageTitle>{props.pageTitle}</PageTitle>
      <Content>{children}</Content>
      <Feedback />
      <Footer />
    </DatasetPageContainer>
  );
};

export default PageTemplate;
