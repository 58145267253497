import styled from 'styled-components';
import { Box } from '@mui/material';

export const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: #272b49;
  border: 1px solid #fff;
  padding: 10px;
  box-sizing: border-box;
`;
