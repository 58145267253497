const DUMMY_USER = {
  __typename: 'UserData',
  email: 'jdoe@goodwaygroup.com',
  fullName: 'Jane Doe',
  id: 2,
  isOkta: true,
  landingPage: '/',
  scope: ['Goodlook-Edit-Access', 'Goodlook-View-Access'],
};

export default DUMMY_USER;
