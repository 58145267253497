import _, { get, uniqBy } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { GET_GRAIN_HIERARCHY, GET_PRICING_GRAINS } from '../../queries/grainHierarchy';
import { GET_ACTIVITIES } from '../../queries/activities';
import React, { useEffect, useRef, useState } from 'react';
import AliasingAccordion from '../components/AliasingAccordion';
import ActivityMappingAccordion from '../components/ActivityMappingAccordion';
import PricingAccordion from '../components/PricingAccordion';
import { v4 as uuidv4 } from 'uuid';
import { GRAINID_GRAIN_MAP, PRICING_MENU_ITEMS, PRICING_TYPE_MAP } from '../../helpers/pricing';
import {
  applyActivityRules,
  applyDimensionRules,
  getInheritedRules,
  mapPricingGrain,
} from '../../helpers/rules';

const DatasetRules = ({
  datasetRules,
  setDatasetRules,
  datasetAssets,
  accountMap,
  dimensions,
  datasetMeta,
  setDimensions,
  activities,
  setActivities,
  pricingRules,
  setPricingRules,
  excludeFinancialColumns,
  setDatasetMeta,
  setLoading,
  setFormError,
  resetSaved,
}) => {
  const [refreshGrainHierarchy, { loading: loadingDimensions }] = useLazyQuery(GET_GRAIN_HIERARCHY);
  const [getActivities, { loading: loadingActivities, data: activitiesData }] =
    useLazyQuery(GET_ACTIVITIES);
  const [refreshPricingGrains, { loading: loadingPricingGrains, data: pricingGrainsData }] =
    useLazyQuery(GET_PRICING_GRAINS);

  const [showDetails, setShowDetails] = useState(!!accountMap?.length);
  const [assets, setAssets] = useState(datasetAssets);
  const [currentDimension, setCurrentDimension] = useState('campaign');
  const [pricingDimensions, setPricingDimensions] = useState([]);
  const fetchedGrains = useRef([]);
  const abortControllerRef = useRef({});

  // tech debt to consolidate pricing rules into the dataset rules object.  This is a temporary measure to
  // create the initial pricing rules.
  const hasInitializedPricingRules = useRef(false);
  const formatAccountMap = () => {
    return accountMap.map(({ platform_grain_id, platform }) => ({
      grainID: platform_grain_id,
      platform,
    }));
  };

  const updateDimensions = data => {
    setDimensions(oldDimensions => {
      const newDimensions =
        data?.getGrainHierarchy?.reduce((dimensionList, dimension) => {
          const { type, id, name, platform, platform_grain_id, path } = dimension;

          if (type) {
            if (!dimensionList[type]) {
              dimensionList[type] = [];
            }

            const newDimension = {
              id,
              grainID: platform_grain_id,
              name: name || platform_grain_id,
              platform,
              path,
            };

            if (!dimensionList.platform) {
              dimensionList.platform = [];
            }

            if (!dimensionList.platform.find(p => p.id == platform)) {
              dimensionList.platform.push({
                id: platform,
                name: platform,
                platform,
              });
            }

            dimensionList[type].push(newDimension);
          }

          return dimensionList;
        }, {}) || [];

      fetchedGrains.current = {
        ...fetchedGrains.current,
        ...Object.keys(newDimensions).reduce((acc, dim) => ({ ...acc, [dim]: true }), {}),
      };

      const updatedDimensions =
        Object.entries({
          ...oldDimensions,
          ...newDimensions,
        }).reduce((dims, [key, values]) => {
          dims[key] = values.map(value => {
            const rules = applyDimensionRules(datasetRules, value.grainID);
            const inheritedRules = getInheritedRules(value.path, datasetRules);

            return {
              ...value,
              inheritedRules,
              ...rules,
            };
          });

          return dims;
        }, {}) || {};

      updatedDimensions.platform = uniqBy(
        [...(oldDimensions?.platform || []), ...(newDimensions?.platform || [])],
        'id'
      );

      resetSaved({ dimensions: updatedDimensions });

      return updatedDimensions;
    });
  };

  const updateActivities = () =>
    setActivities(() => {
      const newActivities = (activitiesData?.getActivitiesByPlatformAccount || []).map(
        (activity, index) => {
          const newActivity = {
            ...activity,
            id: activity.activityID,
            index,
          };

          const activityRules = applyActivityRules(datasetRules, activity, newActivity);

          return { ...newActivity, ...activityRules };
        }
      );
      resetSaved({ activities: newActivities });
      return newActivities;
    });

  const updatePricingDimensions = () => {
    if (!loadingPricingGrains) {
      setPricingDimensions(() => ({
        account: mapPricingGrain(pricingGrainsData?.pricingGrains?.account, datasetRules),
        campaign: mapPricingGrain(pricingGrainsData?.pricingGrains?.campaign, datasetRules),
        placement: mapPricingGrain(pricingGrainsData?.pricingGrains?.placement, datasetRules),
      }));
    }
  };

  const refetchGrains = (accountMap, abortController) => {
    if (accountMap?.length && !fetchedGrains.current[currentDimension]) {
      refreshGrainHierarchy({
        variables: { accountMap, grainTypes: [currentDimension] },
        context: {
          fetchOptions: {
            signal: abortController.signal,
          },
        },
      }).then(({ data }) => updateDimensions(data));
    }
  };

  const refetchActivities = (accountMaps, abortController) => {
    if (accountMaps?.length) {
      getActivities({
        variables: { accountMaps },
        context: {
          fetchOptions: {
            signal: abortController?.signal,
          },
        },
      });
    }
  };

  const refetchPricingGrains = (accountMaps, abortController) => {
    if (accountMaps?.length) {
      refreshPricingGrains({
        variables: { accountMap: accountMaps },
        context: {
          fetchOptions: {
            signal: abortController?.signal,
          },
        },
      });
    }
  };

  useEffect(() => {
    fetchedGrains.current = {};

    if (!!accountMap.length) {
      if (abortControllerRef.current) {
        Object.values(abortControllerRef.current).forEach(controller => controller.abort());
      }

      const refetchGrainsController = new window.AbortController();
      const refetchActivitiesController = new window.AbortController();
      const refetchPricingRulesController = new window.AbortController();

      abortControllerRef.current = {
        refetchGrainsController,
        refetchActivitiesController,
        refetchPricingRulesController,
      };

      const accountMap = formatAccountMap();

      refetchGrains(accountMap, refetchGrainsController);
      refetchActivities(accountMap, refetchActivitiesController);
      refetchPricingGrains(accountMap, refetchPricingRulesController);
    }
  }, [accountMap]);

  useEffect(() => {
    updateActivities();
  }, [activitiesData, datasetRules]);

  useEffect(() => {
    updateDimensions();
    updatePricingDimensions();
    initializePricingRulesAfterLoad();
  }, [datasetRules]);

  useEffect(updatePricingDimensions, [pricingGrainsData]);

  useEffect(
    () => refetchGrains(formatAccountMap(), abortControllerRef.current?.refetchGrainsController),
    [currentDimension]
  );

  // Tech debt SOL-1532: remove this after consolidating rules into dataset rules object.
  const initializePricingRulesAfterLoad = () => {
    if (!hasInitializedPricingRules.current && datasetRules.length) {
      hasInitializedPricingRules.current = true;
      setPricingRules(() => {
        const updatedPricingRules = datasetRules
          .filter(rule => rule.type === 'calculation')
          .reduce((acc, rule) => {
            const grain = get(GRAINID_GRAIN_MAP, rule.config?.grain, null);

            const grains =
              grain &&
              pricingDimensions &&
              pricingDimensions[grain] &&
              rule.config.grain_ids.reduce((grainsList, grainID) => {
                const pricingGrain = pricingDimensions[grain].find(
                  g => g.platform_grain_id == grainID
                );

                if (pricingGrain) {
                  grainsList.push(pricingGrain);
                }

                return grainsList;
              }, []);

            const config = {
              ...rule.config,
              id: rule.config.id || uuidv4(),
              calculation_type: PRICING_MENU_ITEMS[rule.config.calculation_type],
              grains,
            };

            if (!acc[grain]) {
              acc[grain] = [];
            }

            // margin90 and marginprisma are considered the same calculation type when comparing rules. If calc type is
            // MarginG90 or MarginPrisma -> map to Margin otherwise use the original value (Fee or Markup)
            const config_calc_type = get(
              PRICING_TYPE_MAP,
              config.calculation_type,
              config.calculation_type
            );

            // check for duplicate rules aka same calc type, start_date, all grain_ids are the same as current rule
            const duplicate = acc[grain]?.find(r => {
              const r_calc_type = get(PRICING_TYPE_MAP, r?.calculation_type, r?.calculation_type);
              return (
                r_calc_type === config_calc_type &&
                r?.start_date === config?.start_date &&
                r?.value === config?.value &&
                r?.grain_ids.every(grainID => config?.grain_ids?.includes(grainID))
              );
            });

            if (!duplicate) {
              acc[grain].push(config);
            }

            return acc;
          }, {});
        resetSaved({ pricingRules: updatedPricingRules });

        return updatedPricingRules;
      });
    }
  };

  useEffect(() => setShowDetails(!!accountMap.length), [accountMap]);

  useEffect(() => setAssets(a => _.uniqBy([...a, ...datasetAssets], 'key')), [datasetAssets]);

  useEffect(
    () => setLoading(loadingDimensions || loadingActivities),
    [loadingDimensions, loadingActivities]
  );

  const onUpload = asset => {
    setAssets(initalAssets => {
      return _.uniqBy([...initalAssets, { key: asset.key, url: asset.url }], 'key');
    });
  };

  return (
    <>
      {showDetails && (
        <>
          <AliasingAccordion
            datasetMeta={datasetMeta}
            assets={assets}
            accountMap={accountMap}
            dimensions={dimensions}
            setDimensions={setDimensions}
            loading={loadingDimensions}
            onUpload={onUpload}
            currentDimension={currentDimension}
            setCurrentDimension={setCurrentDimension}
            setDatasetRules={setDatasetRules}
          />
          <ActivityMappingAccordion
            datasetMeta={datasetMeta}
            activities={activities}
            setActivities={setActivities}
            loading={loadingActivities}
            setDatasetRules={setDatasetRules}
          />
          <PricingAccordion
            dimensions={pricingDimensions}
            loading={loadingPricingGrains}
            pricingRules={pricingRules}
            setPricingRules={setPricingRules}
            excludeFinancialColumns={excludeFinancialColumns}
            datasetMeta={datasetMeta}
            setDatasetMeta={setDatasetMeta}
          />
        </>
      )}
    </>
  );
};

export default DatasetRules;
