import { Box } from '@mui/material';
import { Check } from '@mui/icons-material';
import { get } from 'lodash';
import React from 'react';

const AliasColumn = ({ path = ['alias'], ...props } = { path: ['alias'] }) => ({
  field: 'alias',
  hideable: true,
  width: 100,
  headerName: 'Aliased',
  type: 'boolean',
  headerAlign: 'center',
  align: 'center',
  valueGetter: ({ row }) => !!row.alias,
  renderCell: ({ row }) => {
    if (get(row, path)) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Check />
        </Box>
      );
    } else {
      return '';
    }
  },
  ...props,
});

export default AliasColumn;
