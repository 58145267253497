import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const getGrainHeader = str => str.charAt(0).toUpperCase() + str.slice(1) + 's';

export const isValidFloat = value => !isNaN(parseFloat(value));

export const parsePercentOrCurrency = (value, valueUnit) => {
  if (!value) {
    return;
  }

  let parsedValue = parseFloat(value).toString();

  if (valueUnit.symbol == '%') {
    return parsedValue;
  } else {
    //format currency
    const numberFormatter = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 20,
    });
    return numberFormatter.format(parsedValue);
  }
};

export const PRICING_TYPE_MAP = {
  MarginG90: 'Margin',
  MarginPrisma: 'Margin',
  CPM: 'Spend Calculation',
  CPCV: 'Spend Calculation',
};

export const PRICING_CONFIG = [
  {
    label: 'New Account Pricing',
    grain: 'account',
    groupByGrain: 'platform',
  },
  {
    label: 'New Campaign Pricing',
    grain: 'campaign',
    groupByGrain: 'account',
  },
  {
    label: 'New Placement Pricing',
    grain: 'placement',
    groupByGrain: 'campaign',
  },
];

export const GRAINID_GRAIN_MAP = {
  account_id: 'account',
  campaign_id: 'campaign',
  placement_id: 'placement',
};

const validateAll = ({ startDate, value, pricingType, grain, selected }) => {
  if (!value || !startDate || isEmpty(selected) || !pricingType) {
    return false;
  }

  const parsedFloat = parseFloat(value);
  const isValid =
    isValidFloat(value) && parsedFloat >= pricingType.min && parsedFloat <= pricingType.max;

  if (!isValid) {
    return false;
  }

  return {
    start_date: startDate.format('YYYY-MM-DD'),
    value: parsePercentOrCurrency(value, pricingType),
    calculation_type: pricingType,
    grain: `${grain}_id`,
    grains: selected,
    id: uuidv4(),
  };
};

const validateMakeGood = ({ pricingType, grain, selected }) => {
  if (isEmpty(selected)) {
    return false;
  }

  return {
    calculation_type: pricingType,
    grain: `${grain}_id`,
    grains: selected,
    id: uuidv4(),
  };
};

export const PRICING_MENU_ITEMS = {
  MarginG90: {
    name: 'Margin G90',
    id: 'MarginG90',
    text: 'Percentage',
    symbol: '%',
    min: 1,
    max: 100,
    key: 'MarginG90',
    validate: validateAll,
  },
  MarginPrisma: {
    name: 'Margin Prisma',
    id: 'MarginPrisma',
    text: 'Percentage',
    symbol: '%',
    min: 1,
    max: 100,
    key: 'MarginPrisma',
    validate: validateAll,
  },
  Fee: {
    name: 'Fee',
    id: 'Fee',
    text: 'Percentage',
    symbol: '%',
    min: 1,
    max: 100,
    key: 'Fee',
    validate: validateAll,
  },
  Markup: {
    name: 'Markup',
    id: 'Markup',
    text: 'Percentage',
    symbol: '%',
    min: 1,
    max: 100,
    key: 'Markup',
    validate: validateAll,
  },
  CPM: {
    name: 'CPM Spend',
    id: 'CPM',
    text: 'CPM',
    symbol: '$',
    min: 1,
    max: 99999,
    key: 'CPM',
    validate: validateAll,
  },
  CPCV: {
    name: 'CPCV Spend',
    id: 'CPCV',
    text: 'CPCV',
    symbol: '$',
    min: 0,
    max: 99999,
    key: 'CPCV',
    validate: validateAll,
  },
  MakeGood: {
    name: 'Make Good',
    id: 'MakeGood',
    key: 'MakeGood',
    isImmutable: true,
    validate: validateMakeGood,
    filter: ({ row, grain, pricingRules }) =>
      row?.calculation_type?.id == 'MakeGood' ||
      (grain == 'campaign' &&
        !pricingRules?.campaign?.filter(({ calculation_type }) => calculation_type.id == 'MakeGood')
          .length),
  },
};
