import * as React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import StyledButton from '../atoms/StyledButton';
import { CancelButton } from '../atoms/CancelButton';
import CellTextField from '../atoms/CellTextField';

const Confirm = ({
  title,
  message,
  onConfirm,
  open,
  setOpen,
  confirmText = 'Yes',
  color = 'warning',
  actions,
  isInputConfirm = false,
  inputValue = '',
  inputPrompt = 'Please type something',
  setInputUpstream = () => {},
}) => {
  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        background-color='blue'
        sx={{
          '.MuiDialog-paper': {
            padding: '4px 20px 10px 1px',
            backgroundColor: '#272B49',
            color: 'white',
          },
        }}
      >
        <DialogTitle id='alert-dialog-title' mb='-8px'>
          {title}
        </DialogTitle>
        <DialogContent>
          {isInputConfirm && (
            <>
              <DialogContentText id='alert-dialog-prompt' color='white'>
                {inputPrompt}
              </DialogContentText>
              <CellTextField
                value={inputValue}
                placeholder='Clone Name'
                onChange={e => setInputUpstream(e.target.value)}
              />
            </>
          )}
          <DialogContentText id='alert-dialog-description' color='white'>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onCancel={handleCancel} />
          {actions}
          <StyledButton
            variant='contained'
            onClick={handleConfirm}
            marginleft='8px'
            autoFocus
            highlightcolor={color}
            disabled={isInputConfirm && inputValue.length < 5}
          >
            {confirmText}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Confirm;
