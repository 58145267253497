import { platformList } from '../../constants/platformList';
import React from 'react';
import { PlatformLogo } from '../atoms/PlatformLogo';

const PlatformCell = ({ row }) => {
  const platform = platformList[row.platform];

  return platform?.logo ? (
    <PlatformLogo platform={platformList[row.platform]} placement={'left'} />
  ) : (
    row.platform
  );
};

export default PlatformCell;
