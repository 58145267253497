import styled from 'styled-components';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

const StyledQuickFilter = styled(GridToolbarQuickFilter)`
  color: #fff !important;

  /* default */
  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  /* hover */
  && .MuiInput-underline:hover:before {
    border-bottom: none !important;
  }

  /* focused */
  .MuiInput-underline:after {
    border-bottom: none !important;
  }

  .MuiInput-root {
    margin-bottom: -6px;
  }

  & .MuiInput-input {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: white !important;
    font-weight: 300 !important;
    font-spacing: 0.05em !important;
    ::placeholder {
      color: white;
      opacity: 1; /* Firefox */
    }
  }
`;

export default StyledQuickFilter;
