import Amazon from '../assets/platformLogos/amazon.png';
import Apple from '../assets/platformLogos/apple.svg';
import Bing from '../assets/platformLogos/bing.svg';
import DCM from '../assets/platformLogos/dcm.png';
import DV360 from '../assets/platformLogos/dv360.svg';
import Facebook from '../assets/platformLogos/facebook.svg';
import GoogleAds from '../assets/platformLogos/google_ads.svg';
import LinkedIn from '../assets/platformLogos/linkedin.svg';
import Pinterest from '../assets/platformLogos/pinterest.svg';
import Roku from '../assets/platformLogos/roku.svg';
import Snapchat from '../assets/platformLogos/snapchat.svg';
import TTD from '../assets/platformLogos/TTD.svg';
import Twitter from '../assets/platformLogos/twitter.svg';
import TikTok from '../assets/platformLogos/tiktok.svg';
import Innovid from '../assets/platformLogos/innovid.png';
import SamsungAds from '../assets/platformLogos/samsung_ads.svg';

export const platformList = {
  amazon: { logo: Amazon, name: 'Amazon', value: 'amazon' },
  apple: { logo: Apple, name: 'Apple', value: 'apple' },
  bing: { logo: Bing, name: 'Bing Ads', value: 'bing' },
  dcm: { logo: DCM, name: 'Campaign Manager 360', value: 'dcm' },
  dv360: { logo: DV360, name: 'Display & Video 360', value: 'dv360' },
  facebook: { logo: Facebook, name: 'Facebook', value: 'facebook' },
  google_ads: { logo: GoogleAds, name: 'Google Ads', value: 'google_ads' },
  linkedin: { logo: LinkedIn, name: 'LinkedIn', value: 'linkedin' },
  pinterest: { logo: Pinterest, name: 'Pinterest', value: 'pinterest' },
  roku: { logo: Roku, name: 'Roku', value: 'roku' },
  snapchat: { logo: Snapchat, name: 'Snapchat', value: 'snapchat' },
  ttd: { logo: TTD, name: 'The Trade Desk', value: 'ttd' },
  twitter: { logo: Twitter, name: 'Twitter', value: 'twitter' },
  tiktok: { logo: TikTok, name: 'TikTok', value: 'tiktok' },
  innovid: { logo: Innovid, name: 'Innovid', value: 'innovid' },
  samsung: { logo: SamsungAds, name: 'Samsung Ads', value: 'samsung' },
};
