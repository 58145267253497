import { GOODWAY_YELLOW, GOODWAY_SAND_400 } from '../../constants/colors';
import { TextField } from '@mui/material';
import React from 'react';

const CellTextField = ({
  value,
  placeholder,
  label,
  onBlur = () => {},
  onChange = () => {},
  allowSpecialCharacters = false,
  inputProps,
  inputLabelProps,
  style,
}) => {
  let stripSpecial = e => {
    if (!allowSpecialCharacters) {
      e.target.value = e.target.value.replace(/[^\w\s@\._\-|\:(\)!\$\/\\]/gi, '');
    }

    onChange(e);
  };

  return (
    <TextField
      value={value || ''}
      placeholder={placeholder}
      label={label}
      onBlur={onBlur}
      onChange={stripSpecial}
      variant={'outlined'}
      InputLabelProps={inputLabelProps}
      sx={{
        width: '100%',
        position: 'relative',
        marginTop: 1,
        marginBottom: 1,
        input: {
          height: 12,
          color: 'white',
          marginBottom: '-4px',
        },
        fieldset: {
          borderColor: GOODWAY_SAND_400,
          borderRadius: '10px',
        },
        '& .MuiOutlinedInput-root:hover': {
          fieldset: {
            borderColor: GOODWAY_YELLOW,
          },
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused': {
            fieldset: {
              borderColor: GOODWAY_YELLOW,
            },
          },
        },
        ...style,
      }}
      onKeyDown={event => event.stopPropagation()}
      InputProps={inputProps}
    />
  );
};

export default CellTextField;
