import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, CircularProgress, Typography } from '@mui/material';
import { destinationList } from '../constants/destinations';
import { GOODWAY_DARK, GOODWAY_YELLOW } from '../../constants/colors';

const StatusIcon = ({ isFailed, isRunning }) => {
  if (isRunning) {
    return <CircularProgress sx={{ color: GOODWAY_DARK }} size={18} />;
  } else if (isFailed) {
    return <PriorityHighIcon sx={{ color: GOODWAY_YELLOW }} height={18} width={18} />;
  }
  return <DoneIcon sx={{ color: GOODWAY_DARK }} height={18} width={18} />;
};

const DatasetStatus = ({ destinationStatuses }) =>
  Object.entries(destinationStatuses)
    .sort()
    .map(([destinationType, destinationStatus]) => (
      <Box alignItems='center' key={destinationType}>
        <Box display='flex' alignItems='center' style={{ padding: [8, 4] }}>
          <Box
            display='flex'
            alignItems='center'
            style={{ height: 18, width: 18, paddingRight: 8 }}
          >
            <StatusIcon isFailed={!destinationStatus} isRunning={destinationStatus === 'running'} />
          </Box>
          <Typography variant='body1' sx={{ ml: 1 }}>
            {destinationList[destinationType]?.name || destinationType}
          </Typography>
        </Box>
        {!destinationStatus && (
          <Typography variant={'body2'} sx={{ ml: 6, mt: 1 }}>
            Changes may not have saved.
          </Typography>
        )}
      </Box>
    ));

export default DatasetStatus;
