import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { SUBMIT_FEEDBACK } from '../../queries/feedback';
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  Rating,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import Progress from '../molecules/Progress';

const Feedback = () => {
  const [hover, setHover] = useState(false);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [rating, setRating] = useState();
  const [text, setText] = useState();
  const [requestStatus, setRequestStatus] = useState({
    status: null,
    text: '',
    open: false,
  });

  const [submitFeedbackMutation] = useMutation(SUBMIT_FEEDBACK);

  const closeAlert = () => setRequestStatus(oldRequest => ({ ...oldRequest, open: false }));

  const submitFeedback = async () => {
    setRequestStatus({
      open: true,
      status: 'running',
      text: 'Saving Feedback',
    });

    await submitFeedbackMutation({
      variables: {
        text,
        rating: Number(rating),
        url: window.location.href,
      },
    })
      .then(({ data }) => {
        setRequestStatus({
          open: true,
          status: 'success',
          text: 'Feedback Saved',
        });
      })
      .catch(() => {
        setRequestStatus({
          open: true,
          status: 'failed',
          text: 'Could not save feedback',
        });
      });
  };

  return (
    <>
      <Progress requestStatus={requestStatus} onClose={closeAlert} />
      {showFeedbackInput && (
        <Card
          style={{
            position: 'fixed',
            bottom: '4%',
            right: 'calc(4% + 68px)',
            width: 320,
            backgroundColor: '#F2F2F2',
          }}
        >
          <CardContent>
            <Typography variant={'h6'} style={{ color: '#1a1d30' }}>
              Feedback? We'd love to hear it!
            </Typography>
            <Box display={'flex'}>
              <Rating
                style={{
                  color: '#FCD119',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: 12,
                  marginBottom: 12,
                }}
                value={rating}
                size={'large'}
                onChange={event => setRating(event.target.value)}
              />
            </Box>
            <TextField
              fullWidth
              multiline
              minRows={5}
              maxRows={5}
              value={text}
              onChange={event => setText(event.target.value)}
            />
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='flex-end'
              paddingTop={4}
              paddingBottom={1}
            >
              <Button
                onClick={submitFeedback}
                variant={'contained'}
                color='success'
                style={{ backgroundColor: '#272B49' }}
              >
                Submit
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
      <Tooltip
        title={'Click here to send us your feedback!'}
        placement={'left'}
        open={hover && !showFeedbackInput}
      >
        <Fab
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setShowFeedbackInput(feedback => !feedback)}
          sx={{
            position: 'fixed',
            bottom: '4%',
            right: '4%',
            backgroundColor: '#272B49',
          }}
        >
          <InsertCommentIcon style={{ color: hover ? '#272B49' : '#FCD119' }} />
        </Fab>
      </Tooltip>
    </>
  );
};

export default Feedback;
