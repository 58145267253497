import React from 'react';
import { Modal } from '@mui/material';
import { FileUploadContainer } from './FileUploadContainer';
import { ModalContainer } from '../atoms/ModalContainer';

export const FileUploadModal = ({
  assets,
  onApply = () => {},
  isOpen,
  setIsOpen,
  bucket,
  onUpload,
  initialSelection,
}) => {
  const apply = (file, selection) => {
    onApply(file, selection);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ModalContainer>
        <FileUploadContainer
          assets={assets}
          onApply={apply}
          bucket={bucket}
          onUpload={onUpload}
          initialSelection={initialSelection}
        />
      </ModalContainer>
    </Modal>
  );
};

export default FileUploadModal;
