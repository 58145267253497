import { AccordionDetails, styled } from '@mui/material';

export const StyledAccordionDetails = styled(AccordionDetails)({
  '& .MuiAccordionDetails-root': {
    padding: 0,
    color: '#fff',
  },
  '& .css-7uxhhd-MuiAccordionDetails-root': {
    padding: 0,
  },
});
