import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
    object-fit: fill;
    border-radius: 10px;
    border: 3px solid #FCD119;
    max-width: 100%
    max-height: 100%
    margin: ${props => (props.preview ? '0px' : '5px')};
    box-sizing: border-box;
`;

export const FilePreview = ({ preview, data, url }) => (
  <Img uploaded={!!url} preview={preview} src={url || data} />
);
