import { useState } from 'react';
import PropTypes from 'prop-types';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client';
import { loginOnUnauthorizedError, g90LoginLink, appLink } from '@goodwaygroup/ui-ux';
import Toast from './Toast';
import Env from '../../helpers/Env';
import { publish } from '../../event';
import { get, last } from 'lodash';

const USER_ABORTED_REQUEST_CODE = 20;

const ApolloWrapper = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (!networkError?.code == USER_ABORTED_REQUEST_CODE) {
      const error = graphQLErrors || networkError;
      const path = last(get(error, '[0].path'));
      getErrorMessage(path || '');

      publish('showGraphQLError');
    }
  });

  const getErrorMessage = path => {
    if (path === 'getAllDatasets') {
      setErrorMessage('Error retrieving datasets.');
    } else if (path === 'destinations') {
      setErrorMessage('Error retrieving destinations.');
    } else if (path === 'accounts') {
      setErrorMessage('Error retrieving account maps.');
    } else {
      setErrorMessage('Something went wrong.');
    }
  };
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      loginOnUnauthorizedError(() => client),
      g90LoginLink(Env('VITE_APP_API_LOOKING_GLASS_URL')),
      appLink(Env('VITE_APP_API_URL')),
    ]),
    cache,
  });

  return (
    <ApolloProvider client={client}>
      <>
        {children}
        <Toast severity='error' isSubscribed={true}>
          {`${errorMessage} We have been alerted.`}
        </Toast>
      </>
    </ApolloProvider>
  );
};

ApolloWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloWrapper;
