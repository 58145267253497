import React, { useEffect, useRef, useState } from 'react';
import { getFileName } from '../../helpers/file';
import { Tooltip } from '@mui/material';
import StyledButton from './StyledButton';
import { FileUploadModal } from '../molecules/FileUploadModal';

const FileUploadCell = ({ assets, onUpload, datasetMeta, row, applyRowChange, rowIndex }) => {
  const [fileName, setFileName] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (row.tags?.creative?.type == 'image') {
      const rowCreative = row.tags?.creative?.file?.key;
      const name = rowCreative ? getFileName(rowCreative) : null;

      setFileName(name);
    } else {
      setFileName();
    }
  }, [row.tags?.creative]);

  const onApply = file => {
    applyRowChange(
      row,
      {
        type: 'image',
        file,
        config: {
          grain: 'creative',
          grain_id: row.grainID,
          key: row.grainID,
        },
      },
      rowIndex,
      !file
    );

    setFileName(file?.key ? getFileName(file.key) : null);
  };

  return (
    <>
      <div>
        <Tooltip placement='left' title={fileName} arrow={true}>
          <StyledButton
            variant={!!fileName ? 'text' : 'outlined'}
            hoverColor={!!fileName ? '#fcd119' : null}
            color='success'
            onClick={() => setIsOpen(true)}
          >
            {fileName || 'Upload'}
          </StyledButton>
        </Tooltip>
      </div>
      <FileUploadModal
        assets={assets}
        onApply={onApply}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        bucket={datasetMeta.assetBucket}
        onUpload={onUpload}
        initialSelection={row.tags?.creative?.type == 'image' && row.tags?.creative?.key}
      />
    </>
  );
};

export default FileUploadCell;
