import { areAllItemsEqual } from '../utils';
import { useEffect, useState } from 'react';

const useBulkEditState = (column = {}, selectedRows = [], valueGetter = () => {}) => {
  const equal = areAllItemsEqual(selectedRows.map(row => valueGetter({ row })));

  const getValue = () => (equal ? valueGetter({ row: selectedRows[0] }) : null);

  const [value, setValue] = useState(getValue());

  useEffect(() => setValue(getValue()), [selectedRows]);

  return [value, setValue];
};

export default useBulkEditState;
