import { Typography } from '@mui/material';
import React from 'react';

export const BulkEditSubheaderSection = ({ subheader, style = {} }) => (
  <Typography
    variant={'p'}
    sx={{
      color: 'white',
      fontSize: 14,
      fontWeight: 300,
      marginLeft: 2,
      ...style,
    }}
  >
    {subheader}
  </Typography>
);
