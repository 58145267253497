import StyledQuickFilter from '../atoms/StyledQuickFilter';
import styled from 'styled-components';
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { GridToolbarColumnsButton, GridToolbarExport } from '@mui/x-data-grid-pro';

import React from 'react';

const StyledFilterButton = styled(GridToolbarFilterButton)`
  .MuiButton-startIcon {
    margin-right: 0 !important;
  }
`;

const StyledColumnsButton = styled(GridToolbarColumnsButton)`
  &.MuiButtonBase-root {
    color: white;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 200;
    text-transform: capitalize;
    font-size: 16px;
  }
`;

const StyledExportButton = styled(GridToolbarExport)`
  &.MuiButtonBase-root {
    color: white;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 200;
    text-transform: capitalize;
    font-size: 16px;
  }
`;

const DataGridToolbar = ({ children, columnHide, exportCsv }) => (
  <GridToolbarContainer
    style={{
      display: 'flex',
      marginBottom: 16,
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    }}
  >
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      {children}
      <StyledFilterButton />
      <StyledQuickFilter />
    </div>
    <div style={{ display: 'flex' }}>
      {columnHide ? <StyledColumnsButton /> : <></>}
      {exportCsv ? <StyledExportButton printOptions={{ disableToolbarButton: true }} /> : <></>}
    </div>
  </GridToolbarContainer>
);

export default DataGridToolbar;
