export const ACTIVITY_GROUPS = [
  { name: 'Conversions', id: 'conversions' },
  { name: 'Actions', id: 'actions' },
  { name: 'Leads', id: 'leads' },
  { name: 'Upper Funnel', id: 'upper_funnel' },
  { name: 'Mid Funnel', id: 'mid_funnel' },
  { name: 'Lower Funnel', id: 'lower_funnel' },
  { name: 'Purchases', id: 'purchases' },
  { name: 'Site Visits', id: 'site_visits' },
  { name: 'Adds to Carts', id: 'adds_to_cart' },
];
