import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_ACCOUNTS } from '../../queries/grainHierarchy';
import { matchSorter } from 'match-sorter';
import { Autocomplete, Box, Divider, Popper, Tabs, Tab } from '@mui/material';
import { GOODWAY_DARK, GOODWAY_LIGHT, GOODWAY_WHITE, GOODWAY_YELLOW } from '../../constants/colors';
import StyledTextField from '../atoms/StyledTextField';
import { PlatformLogo } from '../atoms/PlatformLogo';
import { platformList } from '../../constants/platformList';
import { isEqual, uniqBy } from 'lodash';

const SELECTION_TYPES = [
  {
    type: 'accounts',
    name: 'Accounts',
  },
  {
    type: 'managerAccounts',
    name: 'Manager Accounts',
  },
];

export const AccountSelector = ({
  open,
  setOpen,
  accountMap,
  setAccountMap,
  accountSearchInputRef,
}) => {
  const [selectionType, setSelectionType] = useState('accounts');
  const [inputValue, setInputValue] = useState('');
  const { data, loading } = useQuery(GET_ALL_ACCOUNTS);
  const [disabledManagerAccounts, setDisabledManagerAccounts] = useState([]);

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, { keys: ['name', 'platform_grain_id'] }).slice(0, 25);

  const getUniqueAccounts = values =>
    uniqBy(
      values.map(value => (value.__typename == 'ManagerAccount' ? value.accounts : value)).flat(),
      acc => acc.platform_grain_id
    );

  useEffect(() => {
    const accountMapIds = accountMap.map(({ platform_grain_id }) => platform_grain_id);

    const selected = data?.managerAccounts?.reduce(
      (selectedMccs, { platform_grain_id: mccId, accountIds }) => {
        if (accountIds.every(accountId => accountMapIds.includes(accountId))) {
          selectedMccs.push(mccId);
        }

        return selectedMccs;
      },
      []
    );

    setDisabledManagerAccounts(selected || []);
  }, [accountMap, data?.managerAccounts]);

  return (
    <Autocomplete
      loading={loading}
      filterOptions={filterOptions}
      sx={{ width: '100%', path: { color: GOODWAY_YELLOW } }}
      renderTags={() => null}
      open={open}
      PopperComponent={({ children, index, ...props }) => (
        <Popper
          {...props}
          placement={'bottom'}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
              options: {
                altBoundary: true,
                rootBoundary: 'document',
                padding: 8,
              },
            },
          ]}
        >
          <Box style={{ marginTop: 12, background: GOODWAY_WHITE }}>
            <Tabs
              value={selectionType}
              variant={'fullWidth'}
              textColor={'inherit'}
              sx={{
                '.MuiTabs-indicator': {
                  backgroundColor: GOODWAY_DARK,
                },
              }}
            >
              {SELECTION_TYPES.map(({ name, type }) => (
                <Tab
                  label={name}
                  value={type}
                  onClick={() => setSelectionType(type)}
                  key={`tab-${name}`}
                />
              ))}
            </Tabs>
            {children}
          </Box>
        </Popper>
      )}
      multiple
      options={data?.[selectionType] || []}
      filterSelectedOptions
      disableClearable
      clearOnBlur={false}
      clearOnEscape={false}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (event && event.type === 'blur') {
          setInputValue('');
        } else if (reason !== 'reset') {
          setOpen(true);
          setInputValue(value);
        }
      }}
      value={accountMap}
      onChange={(event, values) => setAccountMap(getUniqueAccounts(values))}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.platform_grain_id === value.platform_grain_id}
      getOptionDisabled={option =>
        selectionType == 'managerAccounts' &&
        disabledManagerAccounts.includes(option.platform_grain_id)
      }
      renderInput={params => (
        <>
          <StyledTextField
            {...params}
            inputRef={input => (accountSearchInputRef.current = input)}
            onFocus={() => setOpen(true)}
            variant='standard'
            placeholder='Search for accounts to add'
            inputProps={{
              ...params.inputProps,
              onKeyDown: e => {
                if (e.key === 'Enter' || (inputValue === '' && e.key === 'Backspace')) {
                  e.stopPropagation();
                }
              },
            }}
          />
          <Divider sx={{ bgcolor: 'white' }} />
        </>
      )}
      renderOption={(props, option) => (
        <Box
          onClick={e => {
            e.stopPropagation();
          }}
          {...props}
          key={option.platform_grain_id}
          style={{ borderBottom: `1px solid ${GOODWAY_LIGHT}` }}
        >
          <Box style={{ width: 18, height: 18, marginRight: 12 }}>
            <PlatformLogo platform={platformList[option.platform]} />
          </Box>
          <span style={{ marginLeft: '8px' }}>{option.name}</span>
        </Box>
      )}
    />
  );
};
