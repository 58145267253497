import { CardHeader } from '@mui/material';
import { ColumnHeader } from '../atoms/ColumnHeader';
import BulkEditSubheader from './BulkEditSubheader';
import React from 'react';

export const BulkEditModalHeader = ({ selectedRows, editObjectName, rows, getRowSubheader }) => (
  <>
    <CardHeader
      title={<ColumnHeader>{`Editing ${selectedRows.length} ${editObjectName}`}</ColumnHeader>}
      style={{ borderRadius: 15 }}
      subheader={
        <BulkEditSubheader
          rows={rows}
          selectedRows={selectedRows}
          getRowSubheader={getRowSubheader}
        />
      }
    />
  </>
);
