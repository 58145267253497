import { CardActions, CardContent } from '@mui/material';
import React, { useMemo } from 'react';
import useBulkEditState from './hooks/useBulkEditState';
import { ApplyButton } from '../components/atoms/ApplyButton';
import { CancelButton } from '../components/atoms/CancelButton';
import { updateRules } from './rules';

const withBulkEdit =
  Component =>
  ({
    column = {},
    rows = [],
    selectedRowIds = [],
    endEditMode = () => {},
    updateRow,
    showEditActions = true,
    setDatasetRules,
    ...props
  }) => {
    if (!rows.length || !selectedRowIds.length) {
      return null;
    }

    const selectedRows = useMemo(
      () => rows.filter(({ id }) => selectedRowIds.includes(id)),
      [rows, selectedRowIds]
    );

    if (!selectedRows.length) {
      return null;
    }
    const valueGetter = column.valueGetter || (row => row[column.field]);

    const [value, setValue] = useBulkEditState(column, selectedRows, valueGetter);

    const applyChange = props => {
      const newRules = selectedRows.map(selectedRow => updateRow(selectedRow, value, props));

      // this will trigger a use effect in DatasetRules to recreate the dimensions
      setDatasetRules(oldDatasetRules => {
        const updatedRules = [...oldDatasetRules];

        newRules.forEach(rule => {
          const { deleteRule, ...newRule } = rule;
          updateRules(updatedRules, newRule, deleteRule);
        });

        return updatedRules;
      });

      endEditMode();
    };

    return (
      <>
        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
          <Component
            {...props}
            column={column}
            selectedRows={selectedRows}
            value={value}
            setValue={setValue}
            endEditMode={endEditMode}
            applyChange={applyChange}
            showEditAction={column.showBulkEditActions}
          />
        </CardContent>
        {showEditActions && (
          <CardActions style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}>
            <CancelButton onCancel={endEditMode} />
            <ApplyButton onApply={applyChange} />
          </CardActions>
        )}
      </>
    );
  };

export default withBulkEdit;
