import { StyledGridItem } from './StyledGridItem';
import { Typography } from '@mui/material';
import React from 'react';
import { GOODWAY_WHITE } from '../../constants/colors';

export const StyledModalHeader = ({ header }) => (
  <StyledGridItem item xs={12} sx={{ paddingTop: 0 }}>
    <Typography
      id='modal-modal-title'
      variant='h5'
      component='h2'
      gutterBottom={true}
      style={{ color: GOODWAY_WHITE, fontSize: 19, fontWeight: 300, marginBottom: '20px' }}
    >
      {header}
    </Typography>
  </StyledGridItem>
);
