import { Box, Tooltip } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
const Text = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 100;
`;

const TextWithToolTipCell = ({ value, onClick, ...props }) => {
  return (
    <Box
      onClick={onClick}
      style={{ height: '100%', width: '100%' }}
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
    >
      <Tooltip placement='left' title={value} arrow={true}>
        <Text {...props}>{value}</Text>
      </Tooltip>
    </Box>
  );
};
export default TextWithToolTipCell;
