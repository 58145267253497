import { StyledDataGridColumnHeader } from '../atoms/StyledDataGridColumnHeader';
import { BulkEditIcon } from '../atoms/BulkEditIcon';
import React from 'react';

export const BulkEditColumnHeader = ({ headerName, selectedRows, setBulkEditColumn, column }) => (
  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
    <StyledDataGridColumnHeader headerName={headerName} />
    <BulkEditIcon selectedRows={selectedRows} startBulkEdit={() => setBulkEditColumn(column)} />
  </div>
);
