import { BulkEditSubheaderSection } from '../atoms/BulkEditSubheaderSection';
import { Stack } from '@mui/material';
import React, { memo } from 'react';

const BulkEditSubheader = ({ selectedRows = [], rows = [], getRowSubheader = () => {} }) => {
  const otherRows = Math.max(selectedRows.length - 3, 0);
  const displayRows = selectedRows.slice(0, 3).map(rowId => {
    const row = rows.find(({ id }) => id == rowId);
    const subheader = getRowSubheader(row);

    return <BulkEditSubheaderSection subheader={subheader} />;
  });

  if (otherRows > 0) {
    displayRows.push(
      <BulkEditSubheaderSection
        subheader={`and ${otherRows} others`}
        style={{ fontStyle: 'italic', marginTop: 1 }}
      />
    );
  }

  return <Stack style={{ marginTop: 8 }}>{displayRows}</Stack>;
};

export default memo(BulkEditSubheader);
