import { useState, useEffect, useRef } from 'react';

const useStableLoadingState = (loadingStates, stableDelay = 500) => {
  const [isLoading, setIsLoading] = useState(true);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const anyLoading = Object.values(loadingStates).some(state => state);

    if (anyLoading) {
      // If any state is loading, clear any existing timeout and set loading to true
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setIsLoading(true);
    } else {
      // If nothing is loading, start a timeout to set loading to false
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setIsLoading(false);
      }, stableDelay);
    }

    // Cleanup function to clear the timeout
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [loadingStates, stableDelay]);

  return isLoading;
};

export default useStableLoadingState;
