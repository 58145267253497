import { IconButton } from '@mui/material';
import { EditNote } from '@mui/icons-material';
import React from 'react';

export const BulkEditIcon = ({ selectedRows, startBulkEdit = () => {} }) => {
  if (!selectedRows?.length || selectedRows.length < 2) {
    return null;
  }

  return (
    <IconButton
      style={{ padding: 0, flexShrink: 0, marginLeft: 2 }}
      size={'small'}
      onClick={event => {
        event.stopPropagation();
        startBulkEdit();
      }}
    >
      <EditNote />
    </IconButton>
  );
};
