export const UI_FRIENDLY_DIMENSIONS = {
  account: 'Account',
  campaign_group: 'Campaign Group',
  campaign: 'Campaign',
  adgroup: 'Ad Group',
  placement: 'Placement',
  line_item: 'Line Item',
  ad: 'Ad',
  creative: 'Creative',
  keyword: 'Keyword',
};

export const GRAIN_ORDER = [
  'account',
  'campaign_group',
  'campaign',
  'adgroup',
  'placement',
  'line_item',
  'ad',
  'creative',
  'keyword',
];
