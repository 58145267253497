import { Box, styled } from '@mui/material';
import React from 'react';

const FormContainer = styled(Box)`
  && {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    flex-direction: column;
    > * {
      margin-top: 20px;
    }
  }
`;

const Form = ({ children, onSubmit }) => (
  <FormContainer>
    <form autoComplete='off' onSubmit={onSubmit}>
      {children}
    </form>
  </FormContainer>
);

export default Form;
