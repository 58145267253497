import React, { useEffect } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { useInterval } from 'react-use';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ExistingViewsPage from './components/pages/ExistingViews';
import DatasetForm from './components/pages/DatasetForm';
import LoadFullStory from './fullstory/LoadFullStory';
import Env from './helpers/Env';
import LoadPendo from './components/pendo/LoadPendo';
import WarnUserProvider from './helpers/WarnUserProvider';

const App = ({ user }) => {
  const isDev = Env('VITE_NODE_ENV') === 'development';
  const GET_USER_DATA = gql`
    query getUserData {
      userData @rest(type: "UserData", path: "/v1/users/me", method: "GET") {
        email
        fullName
        id
        isOkta
        landingPage
        scope
      }
    }
  `;

  const [checkAuth, { error, data }] = useLazyQuery(GET_USER_DATA, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      const fromGL = encodeURIComponent(`/goodlook`);
      let loginApiUrl = Env('VITE_APP_LOGIN_API_URL');
      loginApiUrl = loginApiUrl.replace('{from}', fromGL);
      window.location.href = loginApiUrl;
    }
  }, [error, data]);

  useInterval(() => {
    if (!isDev) {
      checkAuth();
    }
  }, 30000);

  return (
    <WarnUserProvider>
      <BrowserRouter>
        <LoadFullStory
          orgId={Env('VITE_APP_FULLSTORY_ORG_ID')}
          user={user}
          secret={Env('VITE_APP_FULLSTORY_SECRET')}
        />
        <LoadPendo user={user} />
        <Routes>
          <Route path='/' exact element={<ExistingViewsPage user={user} />} />
          <Route path='/dataset/create' exact element={<DatasetForm user={user} />} />
          <Route path='/dataset/edit/:slug' exact element={<DatasetForm user={user} />} />
        </Routes>
      </BrowserRouter>
    </WarnUserProvider>
  );
};

export default App;
