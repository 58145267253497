import { Typography } from '@mui/material';
import { GOODWAY_YELLOW } from '../../constants/colors';
import React from 'react';

export const StyledDataGridColumnHeader = ({ headerName }) => (
  <Typography
    style={{
      outline: 'none !important',
      fontSize: 15,
      color: GOODWAY_YELLOW,
      fontWeight: 300,
    }}
  >
    {headerName}
  </Typography>
);
