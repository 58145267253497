import { Alert, AlertTitle, LinearProgress, Snackbar } from '@mui/material';

const Progress = ({ requestStatus, onClose, linear = true }) => {
  const getAlertData = () => {
    if (requestStatus.status == 'failed') {
      return {
        ...requestStatus,
        color: 'error',
        severity: 'error',
        variant: 'determinate',
        value: 100,
      };
    } else if (requestStatus.status == 'success') {
      return {
        ...requestStatus,
        color: 'success',
        severity: 'success',
        variant: 'determinate',
        value: 100,
      };
    } else {
      return { ...requestStatus, color: 'inherit', severity: 'info' };
    }
  };
  const alertData = getAlertData();

  return (
    <>
      <Snackbar
        open={!!alertData.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ marginTop: -24, width: '60%' }}
      >
        <Alert
          sx={{
            width: '100%',

            mb: 2,
            '& .MuiAlert-message': {
              width: '100%',
            },
          }}
          onClose={onClose}
          severity={alertData.severity}
        >
          <AlertTitle>{alertData.text}</AlertTitle>
          {!!requestStatus.component && requestStatus.component}
          {linear && <LinearProgress {...alertData} />}
        </Alert>
      </Snackbar>
      {requestStatus?.open && requestStatus?.status === 'running' && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgb(0, 0, 32)',
            position: 'fixed',
            top: '0px',
            opacity: 0.25,
            zIndex: 1200,
          }}
        />
      )}
    </>
  );
};

export default Progress;
