import ChannelSelect from '../atoms/ChannelSelect';
import withBulkEdit from '../../helpers/withBulkEdit';

const BulkEditChannelAutocompleteCell = ({ value, setValue }) => {
  return (
    <ChannelSelect
      selectValue={value}
      onChange={(event, newValue) => setValue(newValue)}
      label={'Channel'}
    />
  );
};

export default withBulkEdit(BulkEditChannelAutocompleteCell);
