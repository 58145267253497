import { Box, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import CellTextField from '../atoms/CellTextField';
import { InheritedValueTooltip } from '../atoms/InheritedValueTooltip';

const InheritableTextInput = ({
  value,
  placeholder,
  onBlur,
  onChange,
  allowSpecialCharacters = false,
  inheritedRules,
}) => {
  const [userModified, setUserModified] = useState(!isEmpty(value));

  const { inheritedAlias, inheritedValue } = inheritedRules || {};

  const onValueChange = event => {
    setUserModified(!!event.target.value);
    onChange(event);
  };

  return (
    <CellTextField
      value={value || ''}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onValueChange}
      allowSpecialCharacters={allowSpecialCharacters}
      inputProps={{
        endAdornment: (
          <>
            {!userModified && placeholder ? (
              <InputAdornment position='end'>
                <Box sx={{ marginTop: '.5rem' }}>
                  <InheritedValueTooltip
                    inheritedValue={inheritedValue}
                    inheritedAlias={inheritedAlias}
                  />
                </Box>
              </InputAdornment>
            ) : null}
          </>
        ),
      }}
    />
  );
};

export default InheritableTextInput;
