import StyledButton from './StyledButton';
import React from 'react';
import { GOODWAY_WHITE } from '../../constants/colors';

export const CancelButton = ({ onCancel }) => (
  <StyledButton
    onClick={onCancel}
    highlightcolor={GOODWAY_WHITE}
    variant='outlined'
    marginleft='10px'
  >
    Cancel
  </StyledButton>
);
