import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataGridToolbar from '../molecules/DataGridToolbar';
import { getGridStringOperators } from '@mui/x-data-grid';
import { GOODWAY_LIGHT, GOODWAY_YELLOW } from '../../constants/colors';
import { ACTIVITY_GROUPS } from '../constants/activities';
import PlatformCell from '../molecules/PlatformCell';
import AliasCell from '../molecules/AliasCell';
import AliasColumn from './datagrid/AliasColumn';
import PlatformMultiSelect from '../molecules/PlatformMultiSelect';
import BulkEditTable from './datagrid/BulkEditTable';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import BulkEditTextField from '../molecules/BulkEditTextField';
import BulkEditBoolean from '../molecules/BulkEditBoolean';
import { startCase } from 'lodash';
import { filterNameOrAlias } from '../../helpers/utils';
import { updateRules } from '../../helpers/rules';

const ActivityMappingAccordion = ({ activities, loading, datasetMeta, setDatasetRules }) => {
  const [activityPlatforms, setActivityPlatforms] = useState([]);
  const applyRowChange = (row, newRule, changedIndex, deleteRule) => {
    setDatasetRules(oldDatasetRules => {
      const updatedRules = [...oldDatasetRules];
      updateRules(updatedRules, newRule, deleteRule);

      return updatedRules;
    });
  };

  useEffect(
    () => setActivityPlatforms(() => [...new Set(activities.map(({ platform }) => platform))]),
    [activities]
  );

  const getRowIndex = row => activities.findIndex(({ id }) => id === row.id);

  const columns = [
    {
      field: 'platform',
      hideable: true,
      headerName: 'Platform',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      filterOperators: getGridStringOperators()
        .filter(operator => operator.value === 'isAnyOf')
        .map(operator => ({
          ...operator,
          InputComponent: props => <PlatformMultiSelect {...props} platforms={activityPlatforms} />,
        })),
      renderCell: ({ row }) => <PlatformCell row={row} />,
    },
    {
      field: 'accountName',
      hideable: true,
      headerName: 'Account Name',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'activityName',
      hideable: true,
      headerName: `Activity (Click Cell to Alias)`,
      minWidth: 350,
      flex: 1,
      valueGetter: ({ row }) => row.alias?.config?.alias || row.activityName,
      getApplyQuickFilterFn:
        searchTerm =>
        ({ row }) =>
          filterNameOrAlias(row.activityName, row.alias?.config?.alias, searchTerm),
      renderCell: ({ row }) => (
        <AliasCell
          row={row}
          applyRowChange={applyRowChange}
          id={row.activityID}
          alias={row.alias?.config?.alias}
          aliasId={row.alias?.config?.id}
          name={row.activityName}
          field={'activity_id'}
          default_field={'activity_name_alias'}
          key_field={row.activityID}
          default_row_name={row.activityName}
          rowIndex={getRowIndex(row)}
        />
      ),
      EditCell: props => (
        <BulkEditTextField
          label={`Activity Alias`}
          updateRow={(row, value) => ({
            type: 'alias',
            deleteRule: !value,
            config: {
              field: 'activity_id',
              default_field: 'activity_name_alias',
              key: row.activityID,
              alias: value,
            },
          })}
          {...props}
        />
      ),
    },
    AliasColumn(),
    ...ACTIVITY_GROUPS.map(({ id, name }) => ({
      field: id,
      headerName: name,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      type: 'boolean',
      valueGetter: ({ row }) => !!row[id],
      renderCell: ({ row }) => (
        <Checkbox
          checked={!!row[id]}
          disableRipple
          onClick={({ target }) => {
            applyRowChange(
              row,
              {
                type: 'tag',
                config: {
                  tag_value: 'activities',
                  tag_type: 'activityMap',
                  reference: true,
                  bucket: id,
                  field: 'activity_id',
                  key: row.activityID,
                  grain: 'account_id',
                  grain_id: row.accountID,
                  type: 'tag',
                },
              },
              getRowIndex(row),
              !target.checked
            );
          }}
        />
      ),
      EditCell: props => (
        <BulkEditBoolean
          updateRow={(row, value) => ({
            type: 'tag',
            deleteRule: !value,
            config: {
              tag_value: 'activities',
              reference: true,
              bucket: id,
              field: 'activity_id',
              key: row.activityID,
              grain: 'account_id',
              grain_id: row.accountID,
              type: 'tag',
            },
          })}
          category={startCase(id)}
          {...props}
        />
      ),
    })),
  ];

  return (
    <Accordion
      sx={{
        backgroundColor: GOODWAY_LIGHT,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 1,
        paddingRight: 1,
        marginTop: 3,
        marginBottom: 1,
        borderRadius: '10px',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ pointerEvents: 'auto', color: GOODWAY_YELLOW }} />}
      >
        <Typography variant={'h5'} style={{ color: GOODWAY_YELLOW, fontSize: 19, fontWeight: 300 }}>
          Activities
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item sx={{ width: '100%', height: 12 }}>
          {loading && <LinearProgress />}
        </Grid>
        <BulkEditTable
          cachePath={[datasetMeta.slug, 'activities']}
          data={activities}
          columns={columns}
          toolbar={() => DataGridToolbar({ columnHide: true, exportCsv: true })}
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'platform', 'activityName'],
            },
            columns: {
              columnVisibilityModel: {
                upper_funnel: false,
                mid_funnel: false,
                lower_funnel: false,
                adds_to_cart: false,
                purchases: false,
                site_visits: false,
              },
            },
          }}
          initialSortModel={[{ field: 'activityName', sort: 'asc' }]}
          rowObjectName={'Activities'}
          tableProps={{
            rowHeight: 56,
            checkboxSelection: true,
          }}
          getRowSubheader={row => row.alias?.config?.alias || row.activityName}
          setDatasetRules={setDatasetRules}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ActivityMappingAccordion;
