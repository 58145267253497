/* global pendo */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Env from '../../helpers/Env';

const shouldInitialize =
  Env('VITE_NODE_ENV') === 'production' || Env('VITE_NODE_ENV') === 'staging';

const LoadPendo = ({ user }) => {
  const { id, fullName: name, email } = user;
  useEffect(() => {
    if (shouldInitialize && typeof pendo !== 'undefined') {
      pendo.initialize({
        visitor: {
          id,
          email,
          full_name: name,
        },
        account: {
          id: 'GW0001',
          name: 'Goodway Group',
          is_paying: false,
          monthly_value: 0,
        },
      });
    }
  }, [user]);
  return null;
};

export default LoadPendo;

LoadPendo.propTypes = {
  user: PropTypes.object.isRequired,
};
