import { isEmpty } from 'lodash';

export const getAliases = (datasetRules, platform_grain_id) => {
  const alias = datasetRules?.find(
    rule =>
      rule?.config?.key == platform_grain_id &&
      rule?.type == 'alias' &&
      rule.config.field != 'creative_size_id'
  );
  return alias;
};
export const getSizeAliases = (datasetRules, platform_grain_id) => {
  const sizeAlias = datasetRules?.find(
    rule =>
      rule?.config?.key == platform_grain_id &&
      rule?.type == 'alias' &&
      rule.config.field == 'creative_size_id'
  );
  return sizeAlias;
};
export const getFilters = (datasetRules, platform_grain_id) => {
  const omit = datasetRules.some(
    rule => rule.type == 'grainFilter' && rule.config.key == platform_grain_id
  );
  return omit;
};

export const getTags = (datasetRules, platform_grain_id) => {
  const tags =
    datasetRules
      ?.filter(
        rule =>
          rule.type == 'tag' &&
          rule.config.tag_type == 'alias' &&
          rule.config.grain_id == platform_grain_id
      )
      .reduce((t, tag) => {
        t[tag.config.bucket] = tag;
        return t;
      }, {}) || {};

  const assetsAsTags = datasetRules?.find(
    rule => ['image'].includes(rule.type) && rule.config.grain_id == platform_grain_id
  );

  if (assetsAsTags && !tags.creative) {
    tags.creative = assetsAsTags;
  }

  return tags;
};

export const getActivityBuckets = (datasetRules, activity) =>
  datasetRules?.filter(
    rule =>
      rule?.config?.key == activity.activityID &&
      rule?.type === 'tag' &&
      rule?.config?.tag_value === 'activities'
  ) || [];

export const getActivityAliases = (datasetRules, activity) =>
  datasetRules?.find(
    rule =>
      rule?.type === 'alias' &&
      rule?.config?.key === activity.activityID &&
      rule?.config?.default_field === 'activity_name_alias'
  );

export const applyDimensionRules = (datasetRules, platform_grain_id) => {
  const result = {
    alias: getAliases(datasetRules, platform_grain_id),
    sizeAlias: getSizeAliases(datasetRules, platform_grain_id),
    omit: getFilters(datasetRules, platform_grain_id),
    tags: getTags(datasetRules, platform_grain_id),
  };
  return result;
};

export const applyActivityRules = (datasetRules, activity) => {
  const activityBuckets = getActivityBuckets(datasetRules, activity);
  const activityAlias = getActivityAliases(datasetRules, activity);

  const result = {};
  activityBuckets.forEach(bucket => (result[bucket.config.bucket] = bucket));

  if (activityAlias) {
    result.alias = activityAlias;
  }

  return result;
};

export const getInheritedRules = (path, datasetRules) =>
  path?.reduce(
    (ruleSet, ancestor) => {
      const tags = getTags(datasetRules, ancestor);
      const alias = getAliases(datasetRules, ancestor);

      // path stores data most recent parent first, so first get parents, and only add grandparents
      // if there isn't a parent tag for that object.
      ruleSet.tags = { ...tags, ...ruleSet.tags };

      if (alias && !ruleSet.alias) {
        ruleSet.alias = alias;
      }

      return ruleSet;
    },
    { tags: {} }
  );

const isCreativeUrlRule = rule =>
  rule.type == 'image' || (rule?.type == 'tag' && rule?.config?.bucket == 'creative');

const areBothCreatives = (ruleA, ruleB) => isCreativeUrlRule(ruleA) && isCreativeUrlRule(ruleB);

export const updateRules = (updatedRules, newRule, deleteRule) => {
  const ruleIndex = updatedRules.findIndex(rule => {
    const bothCreatives = areBothCreatives(rule, newRule);
    const isSameRow = rule.config.key == newRule.config.key;

    const isSameType = rule.type == newRule.type;

    let isSameRule = true;

    if (newRule.type == 'tag') {
      isSameRule = newRule.config.bucket == rule.config.bucket;
    } else if (newRule.type == 'alias') {
      isSameRule = newRule.config.field == rule.config.field;
    }

    return isSameRow && ((isSameType && isSameRule) || bothCreatives);
  });

  if (ruleIndex == -1) {
    if (!deleteRule) {
      updatedRules.push(newRule);
    }
  } else {
    if (deleteRule) {
      updatedRules.splice(ruleIndex, 1);
    } else {
      updatedRules[ruleIndex] = newRule;
    }
  }
};

export const mapPricingGrain = (grains, datasetRules) =>
  grains?.map(grain => {
    const alias = getAliases(datasetRules, grain.platform_grain_id);

    if (alias) {
      return {
        ...grain,
        alias,
      };
    }

    return grain;
  }) || [];
