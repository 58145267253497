import { Box, Tooltip } from '@mui/material';
import React, { forwardRef } from 'react';

export const PlatformLogo = forwardRef(({ platform, placement = 'top' }, ref) => {
  const PlatformTooltip = forwardRef((props, ref) => <Tooltip ref={ref} {...props} />);

  return (
    <PlatformTooltip title={platform?.name} placement={placement} arrow={true}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <img src={platform?.logo} style={{ width: 20, height: 20 }} />
      </Box>
    </PlatformTooltip>
  );
});
