import { Grid, LinearProgress } from '@mui/material';
import PreviewDataGridToolbar from '../molecules/PreviewDataGridToolbar';
import React from 'react';
import BulkEditTable from './datagrid/BulkEditTable';
import { formatDimensionName } from '../../helpers/dataset';

const INITIAL_SORT_MODEL = [{ field: 'name', sort: 'asc' }];

const GrainTable = ({
  accountMap,
  loading,
  dimensions = [],
  currentDimension,
  setCurrentDimension,
  columns,
  allowedGrains,
  tableProps = {},
  initialState = {},
  assets,
  datasetMeta,
  setDatasetRules,
}) => {
  return (
    <>
      <Grid item sx={{ width: '100%', height: 12 }}>
        {!!accountMap.length && loading && <LinearProgress />}
      </Grid>
      <Grid container item xs={12}>
        {/*
         The key is needed here to force re-renders when the current dimension changes. MUI's internal data
         gets confused by the quick changes to the data/row object without the explicit rerender.
         */}
        <BulkEditTable
          key={`${currentDimension}-reportingDimensions`}
          cachePath={[datasetMeta.slug, 'reportingDimensions']}
          data={dimensions[currentDimension]}
          columns={columns}
          toolbar={() =>
            PreviewDataGridToolbar({
              grainOptions: allowedGrains,
              currentDimension: currentDimension,
              setCurrentDimension: setCurrentDimension,
            })
          }
          initialState={initialState}
          initialSortModel={INITIAL_SORT_MODEL}
          rowObjectName={`${formatDimensionName(currentDimension)}s`}
          assets={assets}
          getRowSubheader={row => row.alias?.config?.alias || row.name}
          setDatasetRules={setDatasetRules}
          loading={loading}
          {...tableProps}
        />
      </Grid>
    </>
  );
};

export default GrainTable;
