import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Form from '../components/Form';
import PageTemplate from './PageTemplate.jsx';
import StyledButton from '../atoms/StyledButton';
import { destinationList } from '../constants/destinations';
import { GOODWAY_YELLOW } from '../../constants/colors';
import Confirm from '../molecules/Confirm';
import { isValidDatasetName } from '../../helpers/dataset';

import {
  CHECK_DESTINATION_STATUS,
  DELETE_DATASETS_MUTATION,
  GET_DATASET,
  GET_DATASETS_NAMES_QUERY,
  GET_DATASETS_QUERY,
  SAVE_DATASET,
} from '../../queries/dataset';
import DatasetStatus from '../components/DatasetStatus';

import Progress from '../molecules/Progress';
import Destinations from '../molecules/Destinations';
import DatasetAccounts from '../molecules/DatasetAccounts';
import DatasetName from '../molecules/DatasetName';
import DatasetRules from '../molecules/DatasetRules';
import { isEmpty, isEqual } from 'lodash';
import { CancelButton } from '../atoms/CancelButton';
import useWarnUser from '../../helpers/useWarnUser';
import { ConfirmDatasetSave } from '../molecules/ConfirmDatasetSave';
import { isNull } from 'lodash/lang';
import { GET_DESTINATIONS_BY_DATASET_ID } from '../../queries/destinations.jsx';
import { GET_DESTINATION_TYPES } from '../../queries/destinationVersionMaps.jsx';
import SigmaAccordion from '../components/SigmaAccordion.jsx';
import useStableLoadingState from '../../helpers/hooks/useStableLoadingState';
import PartialOverlayModal from '../molecules/PartialOverlayModal';

const StyledInputContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    paddingTop: '1em !important',
  },
}));

const TIMEOUT_DURATION = 10 * 60 * 1000; // 10 minutes

const DatasetForm = ({ user }) => {
  const { slug } = useParams();
  const [datasetMeta, setDatasetMeta] = useState({});
  const [datasetRules, setDatasetRules] = useState([]);
  const [datasetAssets, setDatasetAssets] = useState([]);
  const [accountMap, setAccountMap] = useState([]);
  const [creator, setCreator] = useState({
    name: user.fullName,
    id: user.id,
  });
  const [destinationsInProgress, setDestinationsInProgress] = useState(false);

  const resetDestinations = () =>
    Object.values(destinationList).reduce((defaultDestinations, dest) => {
      if (dest.default) {
        defaultDestinations.push({ ...dest, id: uuidv4() });
      }

      return defaultDestinations;
    }, []);

  const [destinations, setDestinations] = useState(resetDestinations());
  const [requestStatus, setRequestStatus] = useState({
    status: null,
    text: '',
    open: false,
  });
  const [confirmCancelDataset, setConfirmCancelDataset] = useState(false);
  const [confirmSaveDataset, setConfirmSaveDataset] = useState(false);
  const [confirmDeleteDataset, setConfirmDeleteDataset] = useState(false);
  const [dimensions, setDimensions] = useState({});
  const [isEditMode, setEditMode] = useState(typeof slug != 'undefined');
  const [pollDestinationStatus, setPollDestinationStatus] = useState(false);
  const [destinationStatus, setDestinationStatus] = useState({});
  const [activities, setActivities] = useState([]);
  const [pricingRules, setPricingRules] = useState({});
  const [excludeFinancialColumns, setExcludeFinancialColumns] = useState(true);
  const [isCloning, setIsCloning] = useState(false);
  const [cloneName, setCloneName] = useState(undefined);
  const [saveAsName, setSaveAsName] = useState(datasetMeta.name);
  const [sigmaFolderName, setSigmaFolderName] = useState('');
  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const initialRenderTimeoutRef = useRef(null);
  const [queryKey, setQueryKey] = useState(0);
  const [loadingRules, setLoadingRules] = useState(true);
  const [formError, setFormError] = useState(false);
  const destinationStatusRef = useRef({});
  const timeoutRef = useRef(null);

  const [resetSaved] = useWarnUser({
    accountMap,
    activities,
    destinations,
    dimensions,
    pricingRules,
    excludeFinancialColumns,
  });

  const theme = useTheme();
  const nav = useNavigate();
  const largeBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));

  const [saveDataset, { loading: loadingSaveDataset }] = useMutation(SAVE_DATASET, {
    // saves dataset
    refetchQueries: [{ query: GET_DATASETS_QUERY }, 'getAllDatasets'],
  });
  const [deleteDataset, { loading: loadingDeleteDataset }] = useMutation(DELETE_DATASETS_MUTATION, {
    refetchQueries: [{ query: GET_DATASETS_QUERY }, 'getAllDatasets'],
  });
  const [refreshDataset, { loading: loadingDatasetData }] = useLazyQuery(GET_DATASET, {
    fetchPolicy: 'no-cache',
  });
  const { data, loading: loadingAllDatasets } = useQuery(GET_DATASETS_NAMES_QUERY);
  const [getDestinations, { loading: loadingDestinationsData }] = useLazyQuery(
    GET_DESTINATIONS_BY_DATASET_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const { data: destinationTypesData } = useQuery(GET_DESTINATION_TYPES);

  const {
    data: destinationStatusCheck,
    stopPolling,
    startPolling,
    refetch,
  } = useQuery(CHECK_DESTINATION_STATUS, {
    variables: { datasetId: datasetMeta.id, queryKey },
    fetchPolicy: 'no-cache',
    skip: !pollDestinationStatus,
  });

  const closeAlert = () => setRequestStatus(oldRequest => ({ ...oldRequest, open: false }));

  const handleCancel = () => {
    setConfirmCancelDataset(true);
    setConfirmDeleteDataset(false);
  };

  const handleDatasetNameChange = newName => {
    setDatasetMeta(prevMeta => ({
      ...prevMeta,
      name: newName,
    }));
    if (!sigmaFolderName || sigmaFolderName === datasetMeta.name) {
      setSigmaFolderName(newName);
    }
  };

  const handleSigmaFolderNameChange = newAlias => {
    setSigmaFolderName(newAlias);
    setDatasetMeta(prevMeta => ({
      ...prevMeta,
      sigmaFolderName: newAlias,
    }));
  };

  const pollDestinations = () => {
    setQueryKey(prev => prev + 1);
    refetch();
    setPollDestinationStatus(true);
    startPolling(2000);
  };

  const hasSigma = destinations.some(destination => destination.destination === 'sigma');
  const loadingStates = {
    allDataSets: loadingAllDatasets,
    datasetData: loadingDatasetData,
    destinationsData: loadingDestinationsData,
    rules: loadingRules,
  };

  useEffect(() => {
    if (isCloning) {
      setSaveAsName(cloneName);
    } else {
      setSaveAsName(datasetMeta.name);
    }
  }, [isCloning, datasetMeta, cloneName]);

  useEffect(() => {
    if (datasetMeta.excludeFinancialColumns !== undefined) {
      setExcludeFinancialColumns(datasetMeta.excludeFinancialColumns);
    }
    if (!isEditMode) {
      document.title = 'Create Dataset - Goodlook';
    } else {
      if (!isEmpty(datasetMeta)) {
        document.title = datasetMeta.name;
      }
    }
  }, [datasetMeta]);

  useEffect(() => {
    setDestinationsInProgress(requestStatus.status === 'running');
  }, [requestStatus, setDestinationsInProgress]);

  useEffect(() => {
    if (pollDestinationStatus && destinationStatusCheck?.destinationStatus) {
      setDestinationStatus(oldStatus => {
        const destinationSet = new Set(destinations.map(dest => dest.destination));

        return {
          ...oldStatus,
          ...destinationStatusCheck?.destinationStatus.reduce((acc, { destination, status }) => {
            if (destination === 'Goodlook' || destinationSet.has(destination)) {
              acc[destination] = status;
            }
            return acc;
          }, {}),
        };
      });

      const allDestinationStatus = destinations.map(
        destination =>
          destinationStatusCheck.destinationStatus.find(
            dest => dest.destination === destination.destination
          )?.status
      );

      const requestsComplete = allDestinationStatus.every(status => !!status);
      const shouldContinue = allDestinationStatus.some(
        status => status === 'running' || status === undefined
      );

      if (!shouldContinue) {
        setDestinations(oldDestinations => {
          const updatedDestinations = oldDestinations.map(destination => {
            const thisDestinationStatus =
              destinationStatusCheck.destinationStatus.find(
                status => status.destination === destination.destination
              ) || {};

            if (
              thisDestinationStatus.status !== destination.status &&
              thisDestinationStatus !== 'running'
            ) {
              return {
                ...destination,
                result: destination.result,
                status: destination.status || thisDestinationStatus.status,
                resultType:
                  destination.resultType ||
                  (!['redshift', 'sigma'].includes(thisDestinationStatus.destination) && 'url'),
              };
            } else {
              return { ...destination };
            }
          });
          resetSaved({ destinations: updatedDestinations });
          return updatedDestinations;
        });
        stopPolling();
        setPollDestinationStatus(false);
        clearTimeout(timeoutRef.current);

        setRequestStatus(oldStatus => ({
          ...oldStatus,
          open: true,
          status: requestsComplete ? 'success' : 'failed',
        }));

        if (requestsComplete) {
          setTimeout(() => {
            const shouldRefetch = destinations.some(
              destination => destination.destination === 'sigma' && !destination.externalUrl
            );
            if (shouldRefetch) {
              getDestinations({
                variables: { id: datasetMeta.id },
              }).then(response => {
                const refetchDestinations = [...response?.data?.getDestinations];
                setDestinations(oldDestinations => {
                  const updatedDestinations = oldDestinations.map(destination => {
                    return {
                      ...destination,
                      externalUrl: refetchDestinations.find(
                        refetchDestination => refetchDestination.id === destination.id
                      )?.externalUrl,
                    };
                  });
                  resetSaved({ destinations: updatedDestinations });
                  return updatedDestinations;
                });
              });
            }
          }, 4000);
        }
      }
    }
  }, [destinationStatusCheck, pollDestinationStatus]);

  const getLoadedDestinations = destinations => {
    const loadedDestinations = destinations.map(dest => {
      const { destination } = dest;
      const destinationTypeConfig = destinationList[destination];
      const initialConfig = destinationTypeConfig.initialize
        ? destinationTypeConfig.initialize(dest)
        : {};
      const destinationType =
        destinationTypesData?.destinationMaps?.find(type => type.id === dest.destinationMap) || {};

      return {
        id: uuidv4(),
        isSaved: true,
        ...destinationTypeConfig,
        ...initialConfig,
        ...dest,
        externalTemplate: destinationType.externalTemplate,
      };
    });

    return loadedDestinations;
  };

  useEffect(() => {
    if (slug) {
      refreshDataset({
        variables: { slug },
      }).then(response => {
        const datasetData = response?.data?.getDatasetBySlug;

        if (datasetData) {
          const newAccountMap = datasetData?.accounts?.map(({ id, grain }) => ({ ...grain, id }));
          const destinations = getLoadedDestinations(datasetData.destinations);

          if (destinations.some(({ status }) => !status || status == 'running')) {
            setDestinationStatus(
              destinations.reduce((acc, { destination, status }) => {
                acc[destination] = status;
                return acc;
              }, {})
            );
            setTimeout(() => {
              if (!pollDestinationStatus) {
                pollDestinations();
              }
            }, 3000);
          }

          const assetRules = datasetData.datasetLinkedAssets.map(asset => {
            if (asset.type == 'image') {
              return {
                ...asset,
                config: {
                  ...asset.config,
                  key: asset.config.grain_id,
                },
                file: {
                  key: asset.key,
                  url: asset.url,
                },
              };
            } else {
              return {
                ...asset,
                config: {
                  ...asset.config,
                  tag_type: 'alias',
                  tag_value: asset.url,
                  bucket: 'creative',
                  key: asset.config.grain_id,
                },
                type: 'tag',
              };
            }
          });

          const rules = [...datasetData.datasetRules, ...assetRules];

          setDatasetMeta(datasetData);
          setSigmaFolderName(datasetData.sigmaFolderName || datasetData.name);
          setCloneName(`${datasetData.name} COPY`);
          setAccountMap(newAccountMap.length ? newAccountMap : []);
          setDestinations(destinations);
          setDatasetRules(rules);
          setDatasetAssets(datasetData.datasetAssets);
          resetSaved({
            accountMap: newAccountMap.length ? newAccountMap : [],
            destinations: destinations,
            dimensions: dimensions,
            activities: activities,
            pricingRules: pricingRules,
            excludeFinancialColumns: excludeFinancialColumns,
          });
        } else {
          setRequestStatus({
            open: true,
            status: 'failed',
            text: 'Could not load dataset',
          });
        }
      });
    } else {
      //if this is a new dataset assign an assetBucket so we can start uploading prior to saving.
      setDatasetMeta({
        assetBucket: uuidv4(),
      });
      setSigmaFolderName('');
      setAccountMap([]);
      setCreator({
        name: user.fullName,
        id: user.id,
      });

      setDestinations(resetDestinations());
    }
  }, [slug, destinationTypesData]);

  useEffect(() => {
    const statuses = Object.values(destinationStatus);

    if (!timeoutRef.current) {
      if (pollDestinationStatus) {
        timeoutRef.current = setTimeout(() => {
          setRequestStatus({
            open: true,
            status: 'failed',
            text: 'Error saving destinations - Operation timed out. Try saving again.',
          });
          setDestinationsInProgress(false);
          setPollDestinationStatus(false);
          stopPolling();
        }, TIMEOUT_DURATION);
      }
    }

    if (!isEmpty(statuses) && !isEqual(destinationStatusRef.current, destinationStatus)) {
      destinationStatusRef.current = statuses;
      const status = statuses.some(destination => destination == 'running')
        ? 'running'
        : statuses.every(destination => !!destination)
        ? 'success'
        : 'failed';

      setRequestStatus({
        open: true,
        status,
        component: <DatasetStatus destinationStatuses={destinationStatus} />,
        text:
          status == 'running'
            ? 'Saving Dataset - This may take several minutes.'
            : status == 'success'
            ? 'Dataset Saved!'
            : 'Error saving dataset',
      });
    }
  }, [destinationStatus]);

  useEffect(() => {
    if (!sigmaFolderName || sigmaFolderName === datasetMeta.name) {
      setSigmaFolderName(datasetMeta.name);
    }

    if (!isEditMode) {
      // Delay showing the loader for new datasets
      initialRenderTimeoutRef.current = setTimeout(() => {
        setShouldShowLoader(true);
      }, 2000);
    } else {
      setShouldShowLoader(true);
    }

    return () => {
      if (initialRenderTimeoutRef.current) {
        clearTimeout(initialRenderTimeoutRef.current);
      }
    };
  }, [datasetMeta.name, isEditMode, sigmaFolderName]);

  const handleSave = async ({ updateDestinationTemplates = [], updateVersion = false } = {}) => {
    const validateDatasetNameStatus = isValidDatasetName(
      saveAsName,
      isCloning ? undefined : datasetMeta.id,
      data?.getAllDatasets
    );

    if (!validateDatasetNameStatus.status) {
      setRequestStatus({
        open: true,
        status: 'failed',
        text: `${validateDatasetNameStatus.errorMessage}`,
      });
      return;
    }

    if (isEmpty(accountMap)) {
      setRequestStatus({
        open: true,
        status: 'failed',
        text: `Please choose at lease one account to report on.`,
      });
      return;
    }

    setDestinationStatus({
      Goodlook: 'running',
      ...destinations.reduce((acc, { destination }) => {
        acc[destination] = 'running';
        return acc;
      }, {}),
    });
    setRequestStatus(status => ({ ...status, status: null, open: true }));

    try {
      const createdAccount = await createDataset({ updateDestinationTemplates, updateVersion });

      if (!createdAccount.success) {
        throw new Error(createdAccount.message);
      }

      setDatasetMeta(createdAccount.dataset);
      setDestinationStatus(
        createdAccount.dataset.destinations.reduce(
          (newDestinationStatus, { destination }) => {
            newDestinationStatus[destination] = 'running';

            return newDestinationStatus;
          },
          { Goodlook: 'success' }
        )
      );
      setDestinations(getLoadedDestinations(createdAccount.dataset.destinations));

      setEditMode(true);
      nav(`/dataset/edit/${createdAccount.dataset.slug}`);
    } catch (e) {
      setPollDestinationStatus(false);
      stopPolling();
      setRequestStatus({
        open: true,
        status: 'failed',
        text: `An error occurred. Retrying "Save" or refreshing the page may help. If the error persists, please submit a techops ticket`,
      });

      throw e;
    }
    setIsCloning(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setConfirmSaveDataset(true);
  };

  const handleCloning = e => {
    const bool = !isCloning;
    setIsCloning(bool);
  };

  const getDatasetAssets = () => {
    const assets = datasetRules
      .filter(rule => rule.type == 'image')
      .map(({ type, config, file }) => ({ type, key: file.key, config }));

    const references = datasetRules
      .filter(rule => rule.type == 'tag' && rule.config?.bucket == 'creative')
      .map(({ config }) => ({
        type: 'url',
        key: config.tag_value,
        config: {
          grain: 'creative',
          grain_id: config.grain_id,
        },
      }));

    return [...assets, ...references];
  };

  const getDimensionDatasetRules = () =>
    datasetRules.reduce((ruleList, { type, config, id }) => {
      if (config) {
        switch (type) {
          case 'tag': {
            const { bucket, field, grain, grain_id, key, reference, tag_type, tag_value } = config;

            if (
              bucket !== 'creative' &&
              ![bucket, field, grain, grain_id, key, reference, tag_value].some(isNull)
            ) {
              ruleList.push({
                id,
                type,
                config: { bucket, field, grain, grain_id, key, reference, tag_type, tag_value },
              });
            }
            break;
          }

          case 'alias': {
            const { alias, default_field, field, key } = config;
            if (![alias, default_field, field, key].some(isNull)) {
              ruleList.push({ id, type, config: { alias, default_field, field, key } });
            }
            break;
          }

          case 'grainFilter': {
            const { field, key } = config;

            if (![field, key].some(isNull)) {
              ruleList.push({ id, type, config: { field, key } });
            }
            break;
          }
        }
      }

      return ruleList;
    }, []);

  const getPricingDatasetRules = ({ excludeFinancialColumns }) => {
    const rules =
      excludeFinancialColumns || isCloning
        ? [
            { type: 'zeroColumn', config: { name: 'fee' } },
            { type: 'zeroColumn', config: { name: 'markup' } },
            { type: 'zeroColumn', config: { name: 'margin' } },
            { type: 'zeroColumn', config: { name: 'platform_spend' } },
          ]
        : [];
    return Object.values(pricingRules).reduce((finalPricingRules, ruleList) => {
      const newRules = ruleList
        .filter(rule => !isEmpty(rule.grains))
        .map(rule => {
          const datasetRule = {
            config: {
              value: rule.value,
              calculation_type: rule.calculation_type.id,
              start_date: rule.start_date,
              grain: rule.grain,
              grain_ids: rule.grains.map(({ platform_grain_id }) => platform_grain_id),
            },
            type: 'calculation',
          };
          return datasetRule;
        });

      return finalPricingRules.concat(newRules);
    }, rules);
  };

  const createDataset = async ({ updateDestinationTemplates = [], updateVersion = false }) => {
    setConfirmSaveDataset(false);
    const rules = [
      ...getDimensionDatasetRules(),
      ...getPricingDatasetRules({ excludeFinancialColumns }),
    ];

    const variables = {
      updateVersion,
      datasetId: isCloning ? NaN : Number(datasetMeta.id),
      name: saveAsName,
      sigmaFolderName: sigmaFolderName || saveAsName,
      assetBucket: isCloning ? uuidv4() : datasetMeta.assetBucket,
      creator: creator.name || user.fullName,
      creatorId: creator.id || user.id,
      excludeFinancialColumns: isCloning ? true : excludeFinancialColumns,
      accountMap: accountMap.reduce((accountMaps, { platform, type, platform_grain_id }) => {
        if (platform && type && platform_grain_id) {
          accountMaps.push({
            platform,
            grain: type,
            grainID: platform_grain_id,
          });
        }

        return accountMaps;
      }, []),
      destinations: isCloning
        ? [
            {
              id: uuidv4(),
              destination: 'redshift',
              result: null,
              artifact: null,
              status: null,
              update: false,
            },
          ]
        : destinations.map(({ id, destination, result, artifact, status }) => ({
            id,
            destination,
            result,
            artifact,
            status,
            update: updateDestinationTemplates.includes(id),
          })),
      datasetRules: rules,
      datasetAssets: getDatasetAssets(),
    };

    if (isCloning) {
      variables.sigmaFolderName = saveAsName;
    }

    const response = await saveDataset({ variables });

    setTimeout(() => {
      if (!pollDestinationStatus) {
        pollDestinations();
      }
    }, 5000); //TODO: remove this when we have a better solution for the destination status

    return response.data.createDataset;
  };

  const handleDeleteDataset = async () => {
    setRequestStatus({ open: true, status: 'running', text: 'Deleting dataset...' });
    const response = await deleteDataset({
      variables: {
        id: datasetMeta.id,
      },
    });
    if (response?.data?.deleteDatasetById?.code === 200) {
      setRequestStatus({ open: true, status: 'success', text: 'Dataset deleted!' });
      nav('/');
    } else {
      setRequestStatus({ open: true, status: 'failed', text: 'Not all dataset assets deleted.' });
    }
  };

  const isLoading = useStableLoadingState(loadingStates);
  const showLoader = isLoading && shouldShowLoader; //this keeps the loading spinner from showing on initial create render

  const isDisabledBtn =
    isLoading || loadingDeleteDataset || loadingSaveDataset || destinationsInProgress || formError;

  return (
    <PageTemplate pageTitle={isEditMode ? 'Edit Dataset' : 'New Dataset'}>
      <Progress requestStatus={requestStatus} onClose={closeAlert} linear={false} />
      <Form onSubmit={handleSubmit}>
        <Grid container direction='column' justify='flex-start'>
          <Grid container spacing={12} alignItems='stretch' sx={{ marginBottom: 1 }}>
            <Grid item xs={12} md={12} lg={6}>
              <DatasetName
                isEditMode={isEditMode}
                datasetMeta={datasetMeta}
                setDatasetMeta={setDatasetMeta}
                onNameChange={handleDatasetNameChange}
              />
            </Grid>
            <StyledInputContainer
              item
              xs={12}
              md={12}
              lg={6}
              sx={{ marginTop: largeBreakpoint ? 3 : 0 }}
            >
              <Destinations
                isEditMode={isEditMode}
                slug={slug}
                destinations={destinations}
                setDestinations={setDestinations}
                version={datasetMeta?.version?.version}
                handleSave={handleSave}
                isLoading={isLoading}
              />
            </StyledInputContainer>
          </Grid>
          <DatasetAccounts accountMap={accountMap} setAccountMap={setAccountMap} />
        </Grid>
        <PartialOverlayModal isLoading={showLoader}>
          <Box sx={{ minHeight: '190px' }}>
            <DatasetRules
              datasetRules={datasetRules}
              setDatasetRules={setDatasetRules}
              datasetAssets={datasetAssets}
              accountMap={accountMap}
              datasetMeta={datasetMeta}
              dimensions={dimensions}
              setDimensions={setDimensions}
              activities={activities}
              setActivities={setActivities}
              pricingRules={pricingRules}
              setPricingRules={setPricingRules}
              excludeFinancialColumns={excludeFinancialColumns}
              setDatasetMeta={setDatasetMeta}
              setLoading={setLoadingRules}
              setFormError={setFormError}
              resetSaved={resetSaved}
            />
          </Box>
          {hasSigma && (
            <SigmaAccordion
              datasetName={datasetMeta.name}
              sigmaFolderName={sigmaFolderName}
              onSigmaFolderNameChange={handleSigmaFolderNameChange}
            />
          )}
        </PartialOverlayModal>
        <Grid container style={{ marginBottom: 48, marginTop: 50 }}>
          <Grid item align='left' xs={6} sx={{ marginTop: 1 }}>
            {isEditMode && (
              <StyledButton
                variant='outlined'
                onClick={() => setConfirmDeleteDataset(true)}
                highlightcolor='#d32f2f'
              >
                Delete
              </StyledButton>
            )}
          </Grid>
          <Grid item align='right' xs={6} sx={{ marginTop: 1 }}>
            <CancelButton onCancel={handleCancel} />
            {isEditMode && (
              <StyledButton
                marginleft='10px'
                type='submit'
                variant='outlined'
                highlightcolor={GOODWAY_YELLOW}
                onClick={handleCloning}
                disabled={isDisabledBtn}
              >
                Clone
                {/* Duplicate */}
              </StyledButton>
            )}
            <StyledButton
              marginleft='10px'
              type='submit'
              variant='contained'
              color='success'
              bold='true'
              disabled={isDisabledBtn}
            >
              {isEditMode ? 'Save' : 'Create'}
            </StyledButton>
          </Grid>
        </Grid>
      </Form>
      <Confirm
        title={`Cancel ${isEditMode ? 'saving' : 'creating'} dataset?`}
        message={`Cancel ${isEditMode ? 'saving' : 'creating'} dataset? Any changes will be lost.`}
        onConfirm={() => {
          nav('/');
        }}
        open={confirmCancelDataset}
        setOpen={setConfirmCancelDataset}
        color='error'
      />
      <ConfirmDatasetSave
        isEditMode={isEditMode}
        isCloning={isCloning}
        handleSave={handleSave}
        confirmSaveDataset={confirmSaveDataset}
        setConfirmSaveDataset={bool => {
          if (bool) {
            setConfirmSaveDataset(bool);
          }
          setIsCloning(false);
          setConfirmSaveDataset(bool);
        }}
        cloneName={cloneName}
        setCloneName={inputText => {
          setCloneName(inputText);
        }}
        datasetMeta={datasetMeta}
      />
      <Confirm
        title='Delete dataset?'
        message='Deleting a dataset will remove all associated artifacts and data in all destinations. Do you want to proceed?'
        confirmText={'Yes, delete it.'}
        onConfirm={handleDeleteDataset}
        setOpen={setConfirmDeleteDataset}
        open={confirmDeleteDataset}
        color='error'
      />
    </PageTemplate>
  );
};

export default DatasetForm;
