import { Grid, TextField, styled, FormControl } from '@mui/material';
import React, { useState } from 'react';

const UpdatedStyledTextField = styled(TextField)({
  '& label': {
    color: '#fcd119',
    fontSize: '20px',
    fontWeight: 200,
  },
  '& label.Mui-focused': {
    color: '#fcd119',
    '&.Mui-error': {
      color: 'red',
    },
  },
  '& .MuiOutlinedInput-root': {
    color: 'white',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    '& fieldset': {
      borderRadius: 15,
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#fcd119',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fcd119',
    },
    '&.Mui-error fieldset': {
      borderColor: 'red !important',
      '&.Mui-focused, &:hover': {
        borderColor: 'red !important',
      },
    },
  },
  '&.Mui-error label': {
    color: 'red',
  },
  '& legend': {
    padding: '0 1em',
  },
  '&': {
    width: '100%',
  },
});

const DatasetName = ({ isEditMode, datasetMeta, setDatasetMeta, onNameChange }) => {
  const [nameError, setNameError] = useState(false);
  const handleNameChange = e => {
    const newName = e.target.value;
    if (/^[^A-Za-z]/.test(newName.charAt(0))) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    onNameChange(newName);
  };

  return (
    <Grid item xs={12}>
      <Grid container direction='column'>
        <FormControl>
          {isEditMode ? (
            <UpdatedStyledTextField
              id='editName'
              label='Dataset Name'
              variant='outlined'
              value={datasetMeta.name || ''}
              required
              InputLabelProps={{ shrink: true }}
              inputProps={{
                style: {
                  height: '50px',
                  padding: '10px 10px 10px 16px',
                  cursor: 'not-allowed',
                  fontWeight: 100,
                },
                readOnly: true,
              }}
            />
          ) : (
            <Grid item>
              <UpdatedStyledTextField
                id='createNewName'
                label='Dataset Name'
                variant='outlined'
                value={datasetMeta.name || ''}
                required
                helperText={
                  nameError ? 'Dataset Names cannot begin with a number or special character' : ''
                }
                error={nameError}
                onChange={handleNameChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: {
                    height: '50px',
                    padding: '10px 10px 10px 16px',
                    fontWeight: 100,
                  },
                }}
              />
            </Grid>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DatasetName;
